import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserService from "../Services/UserList";
import Swal from "sweetalert2";
import MainLayout from "./admin/MainLayout";
import Loader from "./utils/Loader";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  TablePagination,
  TextField,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import * as XLSX from "xlsx"; 
import { saveAs } from "file-saver"; 
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';

export default function AdminUserList() {
  const [users, setUsers] = useState([]); 
  const [searchQuery, setSearchQuery] = useState(""); 
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(20); 

  useEffect(() => {
    userlistapi();
  }, []);

  const userlistapi = async () => {
    try {
      setLoading(true);
      const response = await UserService.UserList();
      setUsers(response.data.data); // Set all users from API
      setLoading(false);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: error.message,
        timer: 3000, 
      });
      setLoading(false);
    }
  };

  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  
  const filteredUsers = users.filter((user) =>
    `${user.name} ${user.last_name} ${user.email} ${user.emp_code}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  
  const currentUsers = filteredUsers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
const handleDownloadExcel = () => {
    
    const filteredData = filteredUsers.map((user) => ({
      id: user.id,
      name: user.name,
      last_name: user.last_name,
      email: user.email,
      city: user.city,
      state: user.state,
      emp_code: user.emp_code,
      is_active: user.is_active ==1 ? "Active" :"Deactive",
      session_expiration: user.session_expiration,
      created_at: user.created_at,
      updated_at: user.updated_at,
    }));
  const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "UserList.xlsx"); // Save the Blob as an Excel file
  };
  const handleResetPassword =async(data)=>{
    try {
      const response = await UserService.UpdatePassword({id:data});
      
      if (response) {
        Swal.fire({
          icon: "success",
          title: "user",
          text: "password update successfully",
          timer: 3000,
        });
        navigate("/admin/userlist");
      }
      
    } catch (error) {
      
      Swal.fire({
        icon: "warning",
        title: "warning",
        text: error.errors,
        timer: 3000, 
      });
    }
  }

  return (
    <MainLayout>
      {loading && <Loader />}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 3,
          padding: 2,
        }}
      >
        <TextField
          label="Search User"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            width: "100%",
            maxWidth: 400,
            backgroundColor: "#f0f0f0",
            boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
          }}
        />
       <Button
  variant="contained"
  color="primary"
  style={{
    position: "absolute",
    
    right: "10px",  // Adjust this value to position the button from the right
  }}
  onClick={handleDownloadExcel} // Handle Excel download when clicked
>
  Download Excel
</Button>
      </Box>

      <Paper elevation={3} style={{ margin: "1rem", padding: "1rem" }}>
        <TableContainer>
          <Table>
            <TableHead sx={{ background: "#1a1a1a", color: 'white' }}>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: "bold", color:'white'}}>
                  SL No.
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color:'white'}}>
                  First Name
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color:'white'}}>
                  Last Name
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color:'white'}}>
                  Email
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color:'white'}}>
                  Emp Code
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color:'white'}}>
                  City
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color:'white'}}>
                  State
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color:'white'}}>
                  Status
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color:'white'}}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentUsers.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell align="center">
                    {page * rowsPerPage + index + 1}
                  </TableCell>
                  <TableCell align="center">{item.name}</TableCell>
                  <TableCell align="center">{item.last_name}</TableCell>
                  <TableCell align="center">{item.email}</TableCell>
                  <TableCell align="center">{item.emp_code}</TableCell>
                  <TableCell align="center">{item.city}</TableCell>
                  <TableCell align="center">{item.state}</TableCell>
                  <TableCell align="center">
                    {item.is_active === 1 ? (
                      <span style={{ color: "green" }}>Active</span>
                    ) : (
                      <span style={{ color: "red" }}>Deactive</span>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <span className="flex gap-3">
                      <Tooltip title="Edit User" arrow>
                    <IconButton
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={`/admin/edit/${item.id}`}
                    >
                    <EditIcon  className="text-yellow-400"/>
                    </IconButton>
                    </Tooltip>

                      <Tooltip title="Reset Password" arrow>
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={()=>handleResetPassword(item.id)}
                      >
                     <LockResetIcon fontSize="large" className="text-red-400"/>
                    </IconButton>
                      </Tooltip>
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <TablePagination
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            marginTop: "1rem",
          }}
          rowsPerPageOptions={[10, 20, 50, 100, users.length]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </MainLayout>
  );
}
