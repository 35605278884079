    import axios from 'axios';
    import Apiurl from './apiUrl';
    import Cookies from "js-cookie";
    // const user = Cookies.get('token');
    const user = localStorage.getItem("user");
   
    const instance = axios.create({ baseURL: Apiurl});
    // const instance = axios.create({ baseURL: 'https://quatationsapis.groheapp.in/public/api/'});
    // const instance = axios.create({ baseURL: 'https://quatationapis.groheapp.in/api/'});
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    instance.defaults.headers.common['Accept'] = '*/*';
    // instance.defaults.headers.common['Accept-Encoding'] = 'gzip';
    if(user) {
        instance.defaults.headers.common['Authorization'] = 'Bearer '+ user;
    } 
    export default instance;