import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserService from "../../Services/UserList";  // This service should be adjusted to fetch product data
import Swal from "sweetalert2";
import MainLayout from "./MainLayout";
import Loader from "../utils/Loader";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  TablePagination,
  TextField,
  Box,
} from "@mui/material";
import * as XLSX from "xlsx"; // Import the xlsx library
import { saveAs } from "file-saver"; // Import file-saver for saving files

export default function AdminProduct() {
  const [products, setProducts] = useState([]); // All products fetched from API
  const [searchQuery, setSearchQuery] = useState(""); // Search input
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0); // Track current page (0-indexed)
  const [rowsPerPage, setRowsPerPage] = useState(20); // Rows per page

  useEffect(() => {
    productListApi();
  }, []);

  const productListApi = async () => {
    try {
      setLoading(true);
      const response = await UserService.adminProducts();  // Assuming this service fetches product data
      console.log(response.data);
      setProducts(response.data); // Set all products from API
      setLoading(false);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: error.message,
        timer: 3000, // Automatically close after 3 seconds
      });
      setLoading(false);
    }
  };

  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filtered products based on search query
  const filteredProducts = products?.filter((product) =>
    `${product.sku_code} ${product.brand} ${product.product_desc} ${product.finish} ${product.mrp}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  // Paginate filtered products
  const currentProducts = filteredProducts?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Function to handle Excel download
  const handleDownloadExcel = () => {
    const filteredData = filteredProducts?.map((product) => ({
      sku_code: product.sku_code,
      brand: product.brand,
      product_desc: product.product_desc,
      finish: product.finish,
      qty: product.qty,
      mrp: product.mrp,
      disc_perc: product.disc_perc,
      disc_price: product.disc_price,
      gst: product.gst,
      total: product.total,
      type: product.type,
      spare_fg: product.spare_fg,
      product_typ_group: product.product_typ_group,
      collection: product.collection,
      image: product.image,
    }));
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Products");
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "ProductList.xlsx"); // Save the Blob as an Excel file
  };

  return (
    <MainLayout>
      {loading && <Loader />}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 3,
          padding: 2,
        }}
      >
        <TextField
          label="Search Product"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            width: "100%",
            maxWidth: 400,
            backgroundColor: "#f0f0f0",
            boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
          }}
        />
        <Button
          variant="contained"
          color="primary"
          style={{
            position: "absolute",
            right: "10px",  // Adjust this value to position the button from the right
          }}
          onClick={handleDownloadExcel} // Handle Excel download when clicked
        >
          Download Excel
        </Button>
      </Box>

      <Paper elevation={3} style={{ margin: "1rem", padding: "1rem" }}>
        <TableContainer>
          <Table>
            <TableHead sx={{ background: "#1a1a1a", color: 'white' }}>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: "bold", color: 'white' }}>
                  SL No.
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color: 'white' }}>
                  SKU Code
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color: 'white' }}>
                  Brand
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color: 'white' }}>
                  Product Description
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color: 'white' }}>
                  Finish
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color: 'white' }}>
                  MRP
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color: 'white' }}>
                  Image
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color: 'white' }}>
                  Gst % 
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color: 'white' }}>
                    Spare Fg
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color: 'white' }}>
                  Product Type  Group
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color: 'white' }}>
                  Collection
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentProducts?.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell align="center">
                    {page * rowsPerPage + index + 1}
                  </TableCell>
                  <TableCell align="center">{item.sku_code}</TableCell>
                  <TableCell align="center">{item.brand}</TableCell>
                  <TableCell align="center">{item.product_desc}</TableCell>
                  <TableCell align="center">{item.finish}</TableCell>
                  <TableCell align="center">{item.mrp}</TableCell>
                  <TableCell align="center"><img src={item.image} width={100}/></TableCell>
                  <TableCell align="center">{item.gst}%</TableCell>
                  <TableCell align="center">{item.spare_fg}</TableCell>
                  <TableCell align="center">{item.product_typ_group}%</TableCell>
                  <TableCell align="center">{item.collection}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <TablePagination
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            marginTop: "1rem",
          }}
          rowsPerPageOptions={[10, 20, 50, 100,500,1000,2000,5000,10000,11000]}
          component="div"
          count={filteredProducts?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </MainLayout>
  );
}
