import './App.css';
import Login from './components/Login';
import FormAddUser from './components/FormAddUser';
import AdminUserList from './components/AdminUserList';
import EditUserList from './components/EditUserList';
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom'
import Products from './components/users/Products';
import ViewProject from './components/users/ViewProject';
import UserLogin from './components/users/UserLogin';
import Homepage from './components/admin/Homepage';
import MainLayout from './components/admin/MainLayout';
import Summary from './components/users/Summary';
import UserLayout from './components/users/UserLayout';
import UserHomepage from './components/users/UserHomepage';
import UserProjects from './components/users/UserProjects';
import ViewProjects from './components/users/ViewProjects';
import AdminForgotPassword from './components/users/UserForgotPassword';
import CreatePassword from './components/CreatePassword';
// import UpdatePassword from './components/users/UpdatePassword';
import ResetPassword from './components/users/ResetPassword';
import ChangePassword from './components/users/ChangePassword';
import ProtectedRoute from './components/routes/ProtectedRoute';
import UserHelpDesk from './components/users/UserHelpDesk';
import HelpDeskList from './components/admin/HelpDeskList';
import AdminLogin from './components/routes/AdminLogin';
import Log from './components/admin/Log';
import ViewClient from './components/users/ViewClient';
import AddClient from './components/users/AddClient';
import { ToastContainer } from 'react-toastify';
import Projects from './components/admin/Projects';
import ViewProjectDownload from './components/users/ViewProjectDownload';
import UserDashboard from './components/users/UserDashboard';
import AdminDashboard from './components/admin/AdminDashboard';
import AdminClientList from './components/AdminClientList';
import AdminProduct from './components/admin/AdminProduct';
import AdminViewProject from './components/admin/AdminViewProject';
import AdminCoverImages from './components/admin/AdminCoverImages';
import Draft from './components/users/Draft';
import Manual from './components/users/Manual';
function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>

          {/* admin Routes */}
          <Route path='/admin'>
            <Route path='login' element={<Login />}></Route>
            <Route path='home' element={<AdminLogin><Homepage /></AdminLogin>}></Route>
            <Route path='projects' element={<AdminLogin><Projects /></AdminLogin>}></Route>
            <Route path='product' element={<AdminLogin><AdminProduct /></AdminLogin>}></Route>
            <Route path='addUser' element={<AdminLogin><FormAddUser /></AdminLogin>}></Route>
            <Route path='userlist' element={<AdminLogin><AdminUserList /></AdminLogin>}></Route>
            <Route path='clientlist' element={<AdminLogin><AdminClientList /></AdminLogin>}></Route>
            <Route path='edit/:id' element={<AdminLogin><EditUserList /></AdminLogin>}></Route>
            <Route path='viewProjects' element={<AdminLogin><AdminViewProject /></AdminLogin>} />
            <Route path='help/desks' element={<AdminLogin><HelpDeskList /></AdminLogin>}></Route>
            <Route path='log' element={<AdminLogin><Log /></AdminLogin>}></Route>
            <Route path='dashboard' element={<AdminLogin><AdminDashboard /></AdminLogin>}></Route>
            <Route path='cover-images' element={<AdminLogin><AdminCoverImages /></AdminLogin>}></Route>
          </Route>

          {/* user Routes */}
          <Route path="/" element={<UserLogin />} />
          <Route path='forgotPassword' element={<AdminForgotPassword />}></Route>
          <Route path='password-reset/:token' element={<ResetPassword />}></Route>
          <Route path="*" element={<h2>no page found</h2>} />
          <Route path='/user' element={<ProtectedRoute><UserLayout /></ProtectedRoute>}>
            <Route index element={<ProtectedRoute><UserHomepage /></ProtectedRoute>} />
            <Route path='change-password' element={<ProtectedRoute><ChangePassword /></ProtectedRoute>}></Route>
            <Route path='projects/:projectId?' element={<ProtectedRoute><UserProjects /></ProtectedRoute>} />

            <Route path='summary' element={<ProtectedRoute><Summary /></ProtectedRoute>} />
            <Route path='project/download' element={<ProtectedRoute><ViewProjectDownload /></ProtectedRoute>} />
            <Route path='viewProjects' element={<ProtectedRoute><ViewProjects /></ProtectedRoute>} />
            <Route path='view' element={<ProtectedRoute><ViewProject /></ProtectedRoute>} />
            <Route path='help-desk' element={<ProtectedRoute><UserHelpDesk /></ProtectedRoute>}></Route>
            <Route path='products' element={<Products />}></Route>
            <Route path='viewclient' element={<ProtectedRoute><ViewClient /></ProtectedRoute>}></Route>
            <Route path='add-client' element={<ProtectedRoute><AddClient /></ProtectedRoute>}></Route>
            <Route path='dashboard' element={<ProtectedRoute><UserDashboard /></ProtectedRoute>} />
            <Route path='draft' element={<ProtectedRoute><Draft /></ProtectedRoute>} />
            <Route path='manual' element={<ProtectedRoute><Manual /></ProtectedRoute>} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />




    </>
  );
}

export default App;