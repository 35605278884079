import React, { useEffect, useRef, useState } from "react";
import { Tabs, Tab, Box, Button, Modal, Grid, IconButton, TextField } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import MainLayout from "./MainLayout";
import Apiurl from "../../Helpers/apiUrl";
import axios from "axios";
import swal from "sweetalert2";
import { Delete, ZoomIn } from "@mui/icons-material";
import Loader from "../utils/Loader";
import { useDispatch, useSelector } from "react-redux";
import { addCoverImage, addLastImage, addSummaryImage, addThemeImage, fetchCoverImages, fetchLastImages, fetchSummaryImages, fetchThemeImages, deleteImageThunk, addTechImage, addCommunicationImage, fetchTechImages, fetchCommunicationImages } from "../../redux/slices/pdfSlice";

const AdminCoverImages = () => {
  const token = localStorage.getItem("user");
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [name, setName] = useState("")
  const [err, setErr] = useState(false)
  const [openModal, setOpenModal] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const dispatch = useDispatch();
  const { coverImages, summaryImages, themeImages, lastImages,techImages,communicationImages, addStatus, deleteStatus, getStatus } = useSelector((state) => state.pdfImages);
  const fileInputRef = useRef(null);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedImage(null);
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    setSelectedImage(e.target.files[0]);
    setOpenModal(true);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleConfirm = () => {
    if (!selectedImage || !name) {
      setErr(true);
      return;
    }

    switch (selectedTab) {
      case 0:
        dispatch(addCoverImage({ image: selectedImage, name }))
        break;
      case 1:
        dispatch(addSummaryImage({ image: selectedImage, name }))
        break;
      case 2:
        dispatch(addThemeImage({ image: selectedImage, name }))
        break;
      case 3:
        dispatch(addLastImage({ image: selectedImage, name }))
        break;
      case 4:
        dispatch(addTechImage({ image: selectedImage, name }))
        break;
      case 5:
        dispatch(addCommunicationImage({ image: selectedImage, name }))
        break;
      default:
        console.error("Invalid tab selected");
        return;
    }
    setErr(false);
  };


  useEffect(() => {

    switch (selectedTab) {
      case 0:
        if (coverImages.length === 0) {
          dispatch(fetchCoverImages());
        }
        break;
      case 1:
        if (summaryImages.length === 0) {
          dispatch(fetchSummaryImages());
        }
        break;
      case 2:
        if (themeImages.length === 0) {
          dispatch(fetchThemeImages());
        }
        break;
      case 3:
        if (lastImages.length === 0) {
          dispatch(fetchLastImages());
        }
        break;
      case 4:
        if (techImages.length === 0) {
          dispatch(fetchTechImages());
        }
        break;
      case 5:
        if (communicationImages.length === 0) {
          dispatch(fetchCommunicationImages());
        }
        break;
      default:
        console.error("Invalid tab selected");
        break;
    }
  }, [selectedTab])

  useEffect(() => {
    switch (selectedTab) {
      case 0:
        dispatch(fetchCoverImages())
        break;
      case 1:
        dispatch(fetchSummaryImages())
        break;
      case 2:
        dispatch(fetchThemeImages())
        break;
      case 3:
        dispatch(fetchLastImages())
        break;
      case 4:
        dispatch(fetchTechImages())
        break;
      case 5:
        dispatch(fetchCommunicationImages())
        break;
      default:
        console.error("Invalid tab selected");
        return;
    }
  }, [addStatus, deleteStatus])

  useEffect(() => {
    setActiveTab(selectedTab == 0 ? coverImages :
      selectedTab == 1 ? summaryImages :
        selectedTab == 2 ? themeImages :
          selectedTab == 3 ? lastImages :
          selectedTab == 4 ? techImages :
          selectedTab == 5 ? communicationImages :"")
  }, [selectedTab,coverImages,summaryImages,themeImages,lastImages,techImages,communicationImages]);


  const preview = (imageUrl) => {
    if (!imageUrl.startsWith('data:image')) {
      console.error('Invalid file type passed to preview function');
      return;
    }
    const byteString = atob(imageUrl.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: 'image/jpeg' });
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
  };

  useEffect(() => {
    if (addStatus === "succeeded") 
      setOpenModal(false)

    if(addStatus === "succeeded"||addStatus === "failed")
    setName("")

  }, [addStatus])

  const deleteImage = (id) => {
    const typeMap = ["cover", "summary", "theme", "last","tech","communication"];
    const type = typeMap[selectedTab];

    if (type) {
      dispatch(deleteImageThunk({ id, type }));
    } else {
      console.error("Invalid tab selection");
    }
  };

  const pending = getStatus === "pending" || addStatus === "pending" || deleteStatus === "pending";

//   useEffect(()=>{
// if(openModal===false){
//   setSelectedImage(null)
// }
//   },[openModal])


  return (
    <MainLayout>
      {pending && <Loader />}

      <Box className="p-6 bg-gradient-to-br h-screen from-gray-200 to-gray-300 rounded-lg shadow-md">
        {/* Tabs for navigation */}
        <div className="relative">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            className="mb-6 text-sm"
            centered
          >
            <Tab label="Cover Page" />
            <Tab label="Summary Page" />
            <Tab label="Product Selection" />
            <Tab label="Last Page" />
            <Tab label="Technology / MOT's" />
            <Tab label="Push Communication" />
          </Tabs>

          {/* Upload Button */}
          <Box className="absolute top-0 right-4">
            <Button
              variant="contained"
              component="label"
              startIcon={<AddPhotoAlternateIcon />}
              className="bg-gray-600 text-white hover:bg-gray-700 shadow-md"
              disabled={activeTab?.length >= 12}
            >
              Upload
              <input type="file"  ref={fileInputRef} hidden accept="image/*" onChange={handleImageChange} />
            </Button>
          </Box>
        </div>

        {/* Tab Content */}
        <Box sx={{ padding: 0, borderRadius: 2 }}>
          <div className="p-4">
            <Grid container spacing={3} justifyContent="center">
              {activeTab?.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
                  {/* Container for image and text */}
                  <div className="relative flex flex-col items-center justify-center">
                    {/* Image Container with Fixed Size */}
                    <div
                      className="relative w-full border border-gray-500 rounded-lg"
                      style={{ width: 'auto', height: '260px', maxWidth: '210mm', maxHeight: '297mm', overflow: 'hidden' }}
                    >
                      <img
                        src={item.image}
                        alt={item.name}
                        className="w-full h-full object-cover rounded-lg shadow-sm border border-gray-300"
                      />
                    </div>
                    {/* Optional Icons at the Bottom */}
                    <div className="absolute bottom-3 left-1/2 transform -translate-x-1/2 flex space-x-3 z-10">
                      <IconButton onClick={() => preview(item.image)} >
                        <ZoomIn fontSize="large" className="text-gray-50 cursor-pointer bg-blue-600 hover:bg-blue-800 hover:scale-105 border border-gray-50 p-2 rounded-full shadow-md" />
                      </IconButton>
                      <IconButton onClick={() => deleteImage(item.id)} >
                        <Delete fontSize="large" className="text-gray-50 cursor-pointer bg-blue-600 hover:bg-blue-800 hover:scale-105  border border-gray-50 p-2 rounded-full shadow-md" />
                      </IconButton>
                    </div>
                    <span className="absolute top-3 text-[11px] left-1/2 transform -translate-x-1/2 flex space-x-3 w-32 z-10 bg-gray-800 text-white p-3 py-1 rounded-full border ">{item.name}</span>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </Box>

        {/* Image Preview Modal */}
        {openModal&& (

          <Box className="fixed z-40 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-lg shadow-lg space-y-6 max-w-sm w-full" >
            {selectedImage && (
              <img src={selectedImage ? URL.createObjectURL(selectedImage) : ""}
                alt="Preview" className="rounded-lg w-full h-[50vh] border-4 border-gray-200 shadow-md" />
            )}
            <TextField
              label={"Name"}
              variant="outlined"
              fullWidth
              value={name}
              error={err}
              onChange={(e) => setName(e.target.value)}
            />
            <div className="space-x-2 flex">
              <Button variant="contained" color="primary" fullWidth onClick={handleConfirm} disabled={addStatus === "pending"} className="transition-all transform hover:bg-indigo-700" >
                {addStatus === "pending" ? "Saving..." : "Confirm"}
              </Button>
              <Button variant="outlined" color="secondary" fullWidth onClick={() => setOpenModal(false)} className="transition-all transform hover:bg-gray-300" >
                Cancel
              </Button>
            </div>
          </Box>
        )}
      </Box>

    </MainLayout>
  );
};

export default AdminCoverImages;
