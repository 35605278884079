import React, { useState, useEffect } from "react";
import MainLayout from "./MainLayout";
import UserService from "../../Services/UserList";
import Swal from "sweetalert2";
import Loader from "../utils/Loader";
import * as XLSX from "xlsx"; // Import XLSX library for generating Excel files
import { saveAs } from "file-saver"; // Import file-saver to save the file
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Box,
  Button,
} from "@mui/material";

const Log = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Fetch data from the API
  const userLog = async () => {
    try {
      setLoading(true);
      const response = await UserService.loglist();
      setLoading(false);
      setData(response.data);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: error.message || "Something went wrong!",
        timer: 3000,
      });
    }
  };

  useEffect(() => {
    userLog();
  }, []);

  // Filter data based on search query
  const filteredData = data.filter((item) => {
    const query = searchQuery.toLowerCase();
    return (
      query === "" || // If the search query is empty, show all data
      (item.empCode && item.empCode.toLowerCase().includes(query)) || // Check if empCode matches
      (item.name && item.name.toLowerCase().includes(query)) // Check if name matches
    );
  });

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle the Excel download
  const handleDownloadExcel = () => {
    const filteredDataForExcel = filteredData.map((item, index) => ({
      "Sr. No": page * rowsPerPage + index + 1,
      EmpCode: item.empCode,
      Name: item.name,
      IP: item.ip,
      Date: item.created_at ? new Date(item.created_at).toLocaleDateString("en-GB") : "",
      Time: item.updated_at ? new Date(item.updated_at).toLocaleString("en-GB", { hour: "2-digit", minute: "2-digit", hour12: false }) : "",
    }));

    // Convert data to Excel sheet
    const worksheet = XLSX.utils.json_to_sheet(filteredDataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Logs");

    // Write the Excel file to a buffer
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    // Create a Blob from the buffer
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    // Save the file using file-saver
    saveAs(blob, "UserLogs.xlsx"); // Save the Blob as an Excel file
  };

  return (
    <MainLayout>
      {loading && <Loader />}

      <Box sx={{ p: 3 }}>
        {/* Flex container for search and button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // Center the search input horizontally
            alignItems: "center", // Align vertically in the center
            marginBottom: 3,
            padding: 2,
            width: "100%",
          }}
        >
          {/* Search Field */}
          <Box sx={{ flexGrow: 1, maxWidth: 600 }}>
            <TextField
              label="Search Log"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{
                width: "100%",
                maxWidth: 400,
                backgroundColor: "#f0f0f0",
                boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
              }}
            />
          </Box>

          {/* Download Excel Button (Aligned to the right) */}
          <Box sx={{ ml: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownloadExcel}
            >
              Download Excel
            </Button>
          </Box>
        </Box>

        {/* Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ background: "#1a1a1a", color: "white" }}>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: "bold", color: "white" }}>
                  Sr. No
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color: "white" }}>
                  EmpCode
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color: "white" }}>
                  Name
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color: "white" }}>
                  IP
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color: "white" }}>
                  Date
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold", color: "white" }}>
                  Time
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell align="center">{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell align="center">{item.empCode}</TableCell>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">{item.ip}</TableCell>
                    <TableCell align="center">
                      {item ? new Date(item.created_at).toLocaleDateString("en-GB") : ""}
                    </TableCell>
                    <TableCell align="center">
                      {new Date(item.updated_at).toLocaleString("en-GB", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      })}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <TablePagination
          component="div"
          count={filteredData.length}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[10, 20, 50, 100, 500, 1000, filteredData.length]}
        />
      </Box>
    </MainLayout>
  );
};

export default Log;
