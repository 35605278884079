
import Request from "../Helpers/Request";
const UserList = async () => {
    return await Request("admin/userlist", '', 'get');
}
const Projects = async () => {
    return await Request("admin/project/view-list", '', 'get');
}
const EditUser = async (data) => {
    return await Request("admin/userlist/edit/" + data, '', 'get');
}
const UpdateUser = async (data) => {
    return await Request("admin/userlist/update", data);
}
const getprofile = async (data) => {
    return await Request("profile", '', 'get');
}
const helpDeskList = async (data) => {
    return await Request("admin/userlist/help/desk/list", '', 'get');
}
const changeStatus = async (data) => {
    return await Request("admin/userlist/help/desk/status/", data);

};
const helpdesk = async (data) => {
    console.log(data);
    return await Request("admin/userlist/help/desk/status", data);
};
const loglist = async (data) => {
    return await Request("admin/log", '', 'get');
}
const admindashboard = async (data) => {
    return await Request("admin/dashboard", '', 'get');
}
const adminclient = async (data) => {
    return await Request("admin/userlist/client", '', 'get');
}
const adminProducts = async (data) => {
    console.log("api call");
    return await Request("admin/product", '', 'get');
}
const adminAddTheme = async (data) => {
    return await Request("admin/cover-images/add", data);
}
const UpdatePassword = async (data) => {
    return await Request("admin/change-password", data);
}

const UserService = {
    UserList,
    Projects,
    EditUser,
    UpdateUser,
    getprofile,
    helpDeskList,
    changeStatus,
    loglist,
    helpdesk,
    admindashboard,
    adminclient,
    adminProducts,
    adminAddTheme,
    UpdatePassword,
}
export default UserService