import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UserTopbar from "../UserTopbar";
import { useNavigate, useOutletContext } from "react-router-dom";
import UserSection from "../../Services/User/UserLogin";
import Swal from "sweetalert2";
import { deleteDraft } from "../../redux/slices/draftSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../utils/Loader";
const Draft = () => {
  const { isSidebarOpen } = useOutletContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [draftData, setDraftData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading,setLoading]=useState(false)
  const { deleteStatus } = useSelector((state) => state.draft);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  


  
  const viewDraff = async () => {
    try {
setLoading(true)
      const response = await UserSection.viewDraft();
      setDraftData(response.data);
    } catch (error) {
      console.error("Error fetching draft data:", error);
      Swal.fire("Error", "Could not fetch drafts.", "error");
    }finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    viewDraff();
  }, [deleteStatus]);
  
const filteredData = draftData.filter((row) => {
    const combinedData = [
      (row.id || "").toString().toLowerCase(),
      (row.uid || "").toString().toLowerCase(),
      (row.client_name || "").toString().toLowerCase(),
      (row.client_id || "").toString().toLowerCase()
    ].join(' ');
    return combinedData.includes(searchQuery.toLowerCase());
  });

  const visibleRows = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  
  const openDraft = (id) => {
    console.log(id);
    localStorage.setItem("draft_id", id);
    navigate(`/user/projects`);
  };
const deletedraft =(id)=>{
dispatch(deleteDraft(id));
}

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  const value = event.target.value;
  setRowsPerPage(value === "All" ? filteredData.length : parseInt(value, 10));
  setPage(0);
};

let isPending= loading || deleteStatus==="pending"
  return (
    <>
    {isPending&&<Loader/>}

      <UserTopbar heading="Draft" sidebar={isSidebarOpen} />
      <div className=" h-[88vh] p-2 shadow-xl mx-auto">
        {/* Search Bar */}
        <Box
          className="bg-gray-900/90 p-3"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            borderColor: "#fff",
          }}
        >
          <div className="flex justify-between items-center mb-6">
            <input
              type="text"
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search by draft-Id..."
              className="p-3 w-[27rem] border-2 border-teal-800 rounded-md text-black transition duration-200"
              required
            />
          </div>
        </Box>

        <div className="rounded-sm shadow-lg overflow-x-auto">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Sr No
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Draft Id
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Client Name
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Client Id
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Created On
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Last Modified
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="bg-white/80">
                {visibleRows.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredData
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell>
                      {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell>{row.uid}</TableCell>
                      <TableCell>{row.client_name}</TableCell>
                      <TableCell>{row.client_id}</TableCell>
                      <TableCell>
                        {new Date(row.created_at).toLocaleDateString("en-GB", {
                          day: "2-digit", 
                          month: "short", 
                          year: "numeric", 
                          hour: "2-digit", 
                          minute: "2-digit", 
                          second: "2-digit"
                        })}
                      </TableCell>
                      <TableCell>
                        {new Date(row.updated_at).toLocaleDateString("en-GB", {
                          day: "2-digit", 
                          month: "short", 
                          year: "numeric", 
                          hour: "2-digit", 
                          minute: "2-digit", 
                          second: "2-digit"
                        })}
                      </TableCell>
                      <TableCell>
                        <div className="flex space-x-2">
                          <IconButton
                            color="primary"
                            onClick={() => openDraft(row.id)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton color="secondary">
                            <DeleteIcon   onClick={() => deletedraft(row.id)}/>
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>


          <TablePagination
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
            rowsPerPageOptions={[10, 50, 100, 500, "All"]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default Draft;
