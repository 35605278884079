import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import UserSection from "../../Services/User/UserLogin";
import UserTopbar from "../UserTopbar";
import { useOutletContext } from "react-router-dom";
import QuoteGraph from "../QuoteGraph";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const UserDashboard = () => {
  const { isSidebarOpen } = useOutletContext();
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [draft, setdraft] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch client data
  const fetchClientData = async () => {
    try {
      const response = await UserSection.allClients();
      if (response.status) {
        setClients(response.data); // Assume response.data contains an array of clients
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  // Fetch project data
  const fetchProjectData = async () => {
    try {
      setLoading(true);
      const response = await UserSection.projectData();
      if (response.status) {
        setProjects(response.data); // Assume response.data contains an array of projects
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  const fetchDraftData = async () => {
    try {
      setLoading(true);
      const response = await UserSection.viewDraft();
      if (response.status) {
        setdraft(response.data); // Assume response.data contains an array of projects
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };
  // Fetch data on mount
  useEffect(() => {
    fetchClientData();
    fetchProjectData();
    fetchDraftData();
  }, []);

  // Bar chart data based on total clients and total projects
  const chartData = {
    labels: ["Total Clients", "Total Projects"],
    datasets: [
      {
        label: "",
        data: [clients.length, projects.length],
        backgroundColor: ["#FF9800", "#4CAF50"],
        borderColor: ["#388E3C", "#F57C00"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <UserTopbar heading="dashboard" sidebar={isSidebarOpen} />
      <div className="  p-4">
        <div className="grid grid-cols-3 gap-4">
          <div className="bg-gradient-to-r from-purple-500 to-pink-500 p-4 rounded-lg shadow-lg text-white">
            <h3 className="text-lg font-medium">Total Clients</h3>
            <p className="mt-1 text-sm">Total number of clients.</p>
            <div className="mt-2 text-2xl font-bold">{clients.length}</div>
          </div>

          <div className="bg-gradient-to-r from-blue-500 to-teal-500 p-4 rounded-lg shadow-lg text-white">
            <h3 className="text-lg font-medium">Total Projects</h3>
            <p className="mt-1 text-sm">Total number of projects.</p>
            <div className="mt-2 text-2xl font-bold">{projects.length}</div>
          </div>
          <div className="bg-gradient-to-r from-blue-500 to-teal-500 p-4 rounded-lg shadow-lg text-white">
            <h3 className="text-lg font-medium">Total Draft</h3>
            <p className="mt-1 text-sm">Total number of projects in draft.</p>
            <div className="mt-2 text-2xl font-bold">{draft.length}</div>
          </div>
        </div>

    <QuoteGraph data={projects}  chartData={chartData}/>
      </div>
    </>
  );
};

export default UserDashboard;
