import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  TablePagination,
  CircularProgress,
  Button,
  Box,
  TextField, // Import TextField for search functionality
} from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import MainLayout from "./MainLayout";
import UserService from "../../Services/UserList";
import * as XLSX from "xlsx"; // Import the xlsx library
import { saveAs } from "file-saver"; // Import file-saver for saving files

const HelpDeskList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Filtered data for the search functionality
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0); // For Material-UI pagination
  const [rowsPerPage, setRowsPerPage] = useState(10); // Number of items per page
  const [searchQuery, setSearchQuery] = useState(""); // Search query state

  const helpdesk = async () => {
    try {
      setLoading(true);
      const response = await UserService.helpDeskList();
      setLoading(false);
      setData(response.data);
      setFilteredData(response.data); // Initially set filtered data to all data
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: error.message,
        timer: 3000,
      });
    }
  };

  const change_status = async (e, id) => {
    try {
      const status = e.target.value;
      let mergedData = { status, id };
      setLoading(true);
      const response = await UserService.helpdesk(mergedData);
      setLoading(false);
      setData(response.data);
      setFilteredData(response.data); // Re-filter the data after status change
    } catch (error) {
      setLoading(false);
    }
  };

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle search input change and filter data
  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = data.filter((item) =>
      Object.values(item).some(
        (val) => val && val.toString().toLowerCase().includes(query)
      )
    );
    setFilteredData(filtered); // Set filtered data based on search query
  };

  // Excel Download Function
  const handleDownloadExcel = () => {
    const excelData = filteredData.map((item) => ({
      "Ticket ID": item.id,
      "Email": item.email,
      "Category": item.categoery,
      "Message": item.suggestion,
      "Status": item.status,
    }));

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Help Desk");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "HelpDeskList.xlsx");
  };

  useEffect(() => {
    helpdesk();
  }, []);

  return (
    <MainLayout>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", marginTop: 50 }}>
          <CircularProgress />
        </div>
      ) : (
        <Box sx={{ p: 3 }}>
          {/* Search Bar and Excel Download Button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center", // Centering the content horizontally
              alignItems: "center", // Align vertically in the center
              marginBottom: 3,
              padding: 2,
            }}
          >
            {/* Search Field */}
            <Box sx={{ flexGrow: 1, maxWidth: 600 }}>
              <TextField
                label="Search Log"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
                sx={{
                  width: "100%",
                  backgroundColor: "#f0f0f0",
                  boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                }}
              />
            </Box>

            {/* Download Excel Button */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownloadExcel}
              sx={{ marginLeft: 2 }} // Adds spacing between search and button
            >
              Download Excel
            </Button>
          </Box>

          {/* Table with white background */}
          <TableContainer sx={{ backgroundColor: "white" }}>
            <Table>
              <TableHead sx={{ background: "#1a1a1a", color: "white" }}>
                <TableRow>
                <TableCell sx={{ color: "white" }}>
                    <strong>Sr .No</strong>
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    <strong>Ticket ID</strong>
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    <strong>Email</strong>
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    <strong>Category</strong>
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    <strong>Message</strong>
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    <strong>Status</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item,index) => (
                    <TableRow key={item.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>{item.categoery}</TableCell>
                      <TableCell>{item.suggestion}</TableCell>
                      <TableCell>
                        <Select
                          value={item.status}
                          onChange={(e) => change_status(e, item.id)}
                          fullWidth
                        >
                          <MenuItem value="open">Open</MenuItem>
                          <MenuItem value="close">Close</MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <TablePagination
            component="div"
            count={filteredData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 20, 30, 100, 500, filteredData.length]}
          />
        </Box>
      )}
    </MainLayout>
  );
};

export default HelpDeskList;
