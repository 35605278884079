import { configureStore } from '@reduxjs/toolkit';
import pdfReducer from '../slices/pdfSlice';
import draftReducer from '../slices/draftSlice';
import manualReducer from '../slices/manualSlice';

export const store = configureStore({
    reducer: {
        pdfImages: pdfReducer,
        draft: draftReducer,
        manual: manualReducer,
    },
});
