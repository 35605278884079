import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Menu,
    MenuItem,
    Box,
    Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import { GroupAdd as GroupAddIcon } from '@mui/icons-material';
import AddIcon from "@mui/icons-material/PersonAdd";
import GroupIcon from "@mui/icons-material/Group";
import HelpIcon from "@mui/icons-material/Help";
import LogIcon from "@mui/icons-material/History";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import grohe from "../../../src/clientlogo/index_grohe_logo.jpg";
import americanStandard from "../../../src/clientlogo/index_as_logo.gif";
import bgImg from "../../../src/SliderImage/4.jpg"
import WidgetsIcon from '@mui/icons-material/Widgets';
import StorefrontIcon from '@mui/icons-material/Storefront';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PictureAsPdfSharpIcon from '@mui/icons-material/PictureAsPdfSharp';
import BusinessIcon from "@mui/icons-material/Business";
const MainLayout = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeLink, setActiveLink] = useState("home");
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleSidebarClick = (link) => {
        setActiveLink(link);
        // setDrawerOpen(false);
    };

    const handleLogout = () => {
        Cookies.remove("token");
        localStorage.removeItem("user");
        navigate("/admin/login");
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location.pathname]);

    const menuItems = [
        { text: "Home", icon: <HomeIcon />, path: "/admin/home" },
        { text: "Dashboard", icon: <DashboardIcon />, path: "/admin/dashboard" },
        { text: "Projects", icon: <WidgetsIcon />, path: "/admin/projects" },
        { text: "Add User", icon: <AddIcon />, path: "/admin/addUser" },
        { text: "All Users", icon: <GroupIcon />, path: "/admin/userlist" },
        { text: "All Client", icon: <BusinessIcon  />, path: "/admin/clientlist" },
        { text: "Helpdesk", icon: <HelpIcon />, path: "/admin/help/desks" },
        { text: "User Log", icon: <LogIcon />, path: "/admin/log" },
        { text: "Product", icon: <StorefrontIcon />, path: "/admin/product" },

        { text: "PDF Layout", icon: <PictureAsPdfSharpIcon />, path: "/admin/cover-images" },
        
    ];

    const clientLogos = [
        { src: grohe, alt: "Grohe" },
        { src: americanStandard, alt: "American Standard" },
        // { src: lixil, alt: "Lixil" },
    ];

    return (
        <div>
            <div className="absolute inset-0 -z-20">
                <img
                    src={bgImg}
                    autoPlay
                    loop
                    muted
                    className={`w-full h-full object-cover object-center fixed `}
                />
                {/* <div className="absolute inset-0 bg-black opacity-10"></div> */}
            </div>
            {/* Top AppBar */}
            <AppBar
                position="fixed"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    background: "#1a1a1a",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.6)",
                }}
            >
                <Toolbar sx={{ padding: 0, display: "flex", alignItems: "center" }}>
                    {/* Left: Menu Icon and Client Logos */}
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                            color="inherit"
                            edge="start"
                            onClick={toggleDrawer}
                            sx={{ marginLeft: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>

                        <div
                            className="flex gap-6 cursor-pointer"
                            onClick={() => navigate("/admin/home")}
                            style={{ marginLeft: 16 }} // Spacing between menu icon and logos
                        >
                            {clientLogos.map((logo, index) => (
                                <div
                                    key={index}
                                    className="p-2 shadow-md flex items-center justify-center"
                                    style={{
                                        backgroundColor: index === 0 ? "#111F39" : "#fff",
                                    }}
                                >
                                    <img
                                        src={logo.src}
                                        alt={logo.alt}
                                        className="w-12 h-8 object-contain"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Center: LIXIL Text */}
                    <div style={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
                        <div className="flex px-2 items-center gap-2 bg-white p-1 rounded-sm">
                            <p className="text-orange-600 px-2 py-1  text-sm font-bold shadow-sm">
                                LI<span className="text-base">X</span>IL
                            </p>
                        </div>
                    </div>

                    {/* Right: Profile Icon */}
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                            color="inherit"
                            onClick={handleProfileMenuOpen}
                            sx={{ marginRight: 2 }}
                        >
                            <AccountCircleIcon />
                        </IconButton>

                        {/* Profile Menu */}
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleProfileMenuClose}
                            sx={{
                                "& .MuiPaper-root": {
                                    background: "#2a2a2a",
                                    color: "#e0e0e0",
                                },
                            }}
                        >
                            {/* <MenuItem onClick={() => navigate("/profile")}>My Profile</MenuItem> */}
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>

            </AppBar>


            {/* Sidebar  */}
            <Drawer
                variant="permanent"
                open={drawerOpen}
                sx={{
                    width: drawerOpen ? 200 : 80,
                    flexShrink: 0,
                    transition: "width 0.3s ease-in-out",
                    [`& .MuiDrawer-paper`]: {
                        width: drawerOpen ? 200 : 80,
                        background: "#121212",
                        color: "#e0e0e0",
                        boxShadow: "2px 0 8px rgba(0, 0, 0, 0.8)",
                        overflowX: "hidden",
                    },
                }}
            >
                <Toolbar />
                <Divider sx={{ borderColor: "#333" }} />
                <List>
                    {menuItems.map((item) => (
                        <ListItem
                            button
                            key={item.text}
                            selected={activeLink === item.path}
                            onClick={() => {
                                navigate(item.path);
                                handleSidebarClick(item.path);
                            }}
                            sx={{
                                cursor: "pointer",
                                "&.Mui-selected": {
                                    backgroundColor: "#1e1e1e",
                                    "& .MuiListItemText-primary": {
                                        color: "#1e90ff",
                                    },
                                    "&:hover": {
                                        backgroundColor: "#272727",
                                    },
                                },
                                "&:hover": {
                                    backgroundColor: "#1e1e1e",
                                },
                            }}
                        >
                            <ListItemIcon sx={{ color: activeLink === item.path ? "#1e90ff" : "#e0e0e0" }}>
                                {item.icon}
                            </ListItemIcon>
                            {drawerOpen && (
                                <ListItemText
                                    primary={item.text}
                                    primaryTypographyProps={{
                                        fontSize: "15px",
                                        fontWeight: 500,
                                    }}
                                />
                            )}
                        </ListItem>
                    ))}
                </List>
            </Drawer>

            <Box
                sx={{
                    marginLeft: drawerOpen ? "200px" : "80px",
                    padding: "15px",
                    transition: "margin-left 0.3s ease-in-out",
                    marginTop: "64px",
                    minHeight: "100vh",
                }}
            >
                {children}
            </Box>
        </div>
    );
};

export default MainLayout;
