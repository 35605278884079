import React, { useState, useEffect } from "react";
import UserSection from "../../Services/User/UserLogin";
import UserTopbar from "../UserTopbar";
import ExcelJS from "exceljs";
import animationData from "../../animations/loader_animation0.json";
import Lottie from "lottie-react";
import axios from "axios";
import * as XLSX from "xlsx";
import Loader from "../utils/Loader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import DownloadSummary from "../users/DownloadSummary";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import drive from '../../../src/SliderImage/drive.png';
import MainLayout from "./MainLayout";
const AdminViewProject = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const project_id = params.get("project_id");
  const myStyles = {
    // display: 'flex',
    // justifyContent: 'center',
    // border:'1px solid #D3D3D3',
    // borderBottom: '1px solid #D3D3D3',
  };
  const [projectId, setprojectId] = useState(project_id);
  const [projectData, setprojectData] = useState();
  const [projectSection, setSection] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [sectionTotal, setSectionTotal] = useState(0);
  const [sectionId, setSectiionId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [button, setButton] = useState("Download Excel");
  const [disable, setDisable] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  // const { isSidebarOpen } = useOutletContext(1);
  const [excelData, setExcelData] = useState(null);
  // const [clients, setClient] = useState();
  // const [items, setItems] = useState([]);
  // const [project ,setproject] = useState([]);
  const viewprojectById = async () => {
    // e.preventDefault();
    try {
      setLoading(true);
      let res = await UserSection.viewProjectById(projectId);
      if (res.status) {
        setLoading(false);
        const sectionsArray = Object.entries(res.data.section);
        setSectionArr(sectionsArray);
        setprojectData(res.data);
        setSectionTotal(Object.keys(res.data.section).length);
      } else {
        return 0;
      }
    } catch (error) {
      setLoading(true);
      console.log(error);
      // handleApiError(error, 'Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  // import csv file
  const resetstate = (e) => {
    setLoading(false);
    e.preventDefault();
    setprojectId("");
    setSectionArr();
    setSectionTotal();
    setprojectData();
    // window.location.href ='/user/viewProjects';
  };
  useEffect(() => {
    viewprojectById();
  }, []);
  // Helper function to convert a blob to base64

  const formatNumber = (value) => {
    let val = value ? value : 0;
    if (typeof val === "string") {
      val = val.replace(/,/g, "");
    }

    val = parseFloat(val);

    //  nearest whole number
    val = Math.round(val);

    // commas
    return val;
  };

  const [dataFromDownloadSummary, setDataFromDownloadSummary] = useState("");
  const [showComponent, setshowComponent] = useState(false);
  const [excelloading, setExcelLoading] = useState(false);
  const [projectIdDownload, setProjectIdDownload] = useState();

  const handleDataFromDownloadSummary = (data) => {
    setDataFromDownloadSummary(data);
  };

  useEffect(() => {
    if (
      dataFromDownloadSummary == "downloaded" ||
      dataFromDownloadSummary == "failed"
    ) {
      setExcelLoading(false);
    }
  }, [dataFromDownloadSummary]);

  const editProject = (id) => {
    navigate(`/user/projects/${id}`);
  };
  return (
    <>
      <MainLayout>
        <div>
          {excelloading && <Loader />}

          <div className="p-2">
            {error && <p className="text-red-500 text-center">{error}</p>}
            <section>
              <div className="relative p-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                  {/* Client ID */}
                  <div className="bg-gray-900 border border-white p-4 rounded-lg shadow-md hover:shadow-lg transition-all transform hover:scale-105">
                    <div className="flex justify-between mb-2">
                      <div className="text-white text-sm font-medium">
                        Client ID
                      </div>
                      <div className="text-white text-lg font-semibold">

                        Client Name
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="text-white text-sm font-medium">
                        {projectData ? projectData.client_id : "N/A"}
                      </div>
                      <div className="text-white text-lg font-semibold">
                        {projectData ? projectData.client.client_name : "N/A"}
                      </div>
                    </div>
                  </div>


                  {/* Client Name */}
                  <div className="bg-gray-900 border border-white p-4 rounded-lg shadow-md hover:shadow-lg transition-all transform hover:scale-105">
                    <div className="flex justify-between mb-2">
                      <div className="text-white text-sm font-medium">
                        Phone
                      </div>
                      <div className="text-white text-lg font-semibold">

                        Eamil
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="text-white text-sm font-medium">
                        {projectData ? projectData.client.phone : "N/A"}
                      </div>
                      <div className="text-white text-lg font-semibold">
                        {projectData ? projectData.client.email : "N/A"}
                      </div>
                    </div>
                  </div>

                  {/* Billing Address */}
                  <div className="bg-gray-900 border border-white p-4 rounded-lg shadow-md hover:shadow-lg transition-all transform hover:scale-105">
                    <div className="text-white text-sm font-medium mb-2">
                      Billing Address
                    </div>
                    <div className="text-white text-lg font-semibold">
                      {projectData ? projectData.client.site_add : "N/A"}
                    </div>
                  </div>

                  {/* Architect Firm Detail */}
                  <div className="bg-gray-900 border border-white p-4 rounded-lg shadow-md hover:shadow-lg transition-all transform hover:scale-105">
                    <div className="text-white text-sm font-medium mb-2">
                      Architect Firm Detail
                    </div>
                    <div className="text-white text-lg font-semibold">
                      {projectData ? projectData.client.afd : "N/A"}
                    </div>
                  </div>

                  {/* Site Address */}
                  <div className="bg-gray-900 border border-white p-4 rounded-lg shadow-md hover:shadow-lg transition-all transform hover:scale-105">
                    <div className="text-white text-sm font-medium mb-2">
                      Site Address
                    </div>
                    <div className="text-white text-lg font-semibold">
                      {projectData ? projectData.client.add_to : "N/A"}
                    </div>
                  </div>

                  {/* Project ID */}
                  <div className="bg-gray-900 border border-white p-4 rounded-lg shadow-md hover:shadow-lg transition-all transform hover:scale-105">
                    <div className="text-white text-sm font-medium mb-2">
                      Project ID
                    </div>
                    <div className="text-white text-lg font-semibold">
                      {projectData ? projectData.project_id : "N/A"}
                    </div>
                  </div>
                  <div className="bg-gray-900 border border-white p-4 rounded-lg shadow-md hover:shadow-lg transition-all transform hover:scale-105">
                    <div className="text-white text-sm font-medium mb-2 flex justify-between">
                      <div>
                        Date:
                      </div>
                      <div>
                        Valid Date:
                      </div>

                    </div>
                    <div className="text-white text-sm font-medium mb-2 flex justify-between">
                      <div className="text-lg font-semibold">
                        {projectData
                          ? new Date(projectData.created_at).toLocaleDateString("en-GB")
                          : "N/A"}
                      </div>
                      <div className="text-lg font-semibold">
                        {projectData
                          ? new Date(projectData.valid_date).toLocaleDateString("en-GB")
                          : "N/A"}
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center  justify-center space-x-4">
                    {/* Download Button */}
                    <span className="flex flex-col gap-4">
                      <Link
                        to={"/user/project/download"}
                        className="bg-white border border-black hover:border-white hover:bg-gray-900 hover:text-white text-gray-950 w-36 h-10 rounded flex justify-center items-center transition duration-300 ease-in-out gap-2"
                      >
                        <DownloadIcon />
                        <span>Download</span>
                      </Link>

                      {/* Edit Button */}
                      {/* <button
                        onClick={() => editProject(projectData.id)}
                        className="bg-white border border-black hover:border-white hover:bg-gray-900 hover:text-white text-gray-950 w-36 h-10 rounded flex justify-center items-center transition duration-300 ease-in-out gap-2"
                      >
                        <EditIcon />
                        <span>Edit</span>
                      </button> */}
                    </span>
                    {/* Google Drive Link */}
                    {/* <div>
                      <a href="https://drive.google.com/" target="_blank" rel="noopener noreferrer">
                        <img src={drive} alt="Google Drive" width={50} className="transition-transform transform hover:scale-110" />
                      </a>
                    </div> */}
                  </div>


                </div>
              </div>

              <table
                class="text-sm font-mono w-full border-collapse border border-slate-500 ... productTable"
                id="productTable"
              >
                <thead>
                  <tr className="bg-gray-900 text-white client_details">
                    <th class=" text-center border border-slate-600 text-left ...">
                      Product Code
                    </th>
                    <th class="text-center border border-slate-600 text-left ...">
                      Area
                    </th>
                    <th class="text-center border border-slate-600 text-left ...">
                      Brand
                    </th>
                    <th class="text-center border border-slate-600 text-left w-24 ">
                      Product Description{" "}
                    </th>
                    <th class="text-center border border-slate-600 text-left ...">
                      Finish
                    </th>
                    <th class="text-center border border-slate-600 text-left ...">
                      Collection
                    </th>
                    <th class="text-center border border-slate-600 text-left ...">
                      Product Type
                    </th>
                    <th class="text-center border border-slate-600 text-left ...">
                      Product Image
                    </th>
                    <th class="text-center border border-slate-600 text-left ...">
                      Quantity
                      {/* <span className="flex gap-1 items-center justify-center">
                    <ArrowDropUpIcon    onClick={() => sortTableDataAsc("qty")}
                    className="bg-white text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110">

                    </ArrowDropUpIcon>
                    <ArrowDropDownIcon   onClick={() => sortTableDataDesc("qty")}
                     className="bg-white text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110">
                     </ArrowDropDownIcon>
                    </span> */}
                    </th>

                    <th class="text-center border border-slate-600 text-left ...">
                      MRP (₹)
                      {/* <span className="flex gap-1 items-center justify-center">
                    <ArrowDropUpIcon    onClick={() => sortTableMrpDataAsc("mrp")}
                    className="bg-white text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110">

                    </ArrowDropUpIcon>
                    <ArrowDropDownIcon    onClick={() => sortTableMrpDataDesc("mrp")}
                     className="bg-white text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110">
                      
                    </ArrowDropDownIcon> 
                    </span> */}
                    </th>
                    <th class="text-center border border-slate-600 text-left ...">
                      Unit Price (₹)
                    </th>
                    <th class="text-center border border-slate-600 text-left w-24 ">
                      Discount Price (₹)
                      {/* <span className="flex gap-1 items-center justify-center">
                    <ArrowDropUpIcon    onClick={() => sortTableDataAsc("disc_price")}
                    className="bg-white text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110">

                    </ArrowDropUpIcon>
                    <ArrowDropDownIcon    onClick={() => sortTableDataDesc("disc_price")}
                    className="bg-white text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110">
                    </ArrowDropDownIcon>
                    </span> */}
                    </th>
                    <th class="text-center border border-slate-600 text-left ...">
                      Discount %
                      {/* <span className="flex gap-1 items-center justify-center">
                    <ArrowDropUpIcon     onClick={() => sortTableDataAsc("disc_per")}
                    className="bg-white text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110">

                    </ArrowDropUpIcon>
                    <ArrowDropDownIcon    onClick={() => sortTableDataDesc("disc_per")}
                    className="bg-white text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110">
                      
                    </ArrowDropDownIcon>
                    </span> */}
                    </th>
                    <th class="text-center border border-slate-600 text-left ...">
                      GST %
                    </th>
                    <th class="text-center border border-slate-600 text-left ...">
                      Total (₹)
                      {/* <span className="flex gap-1 items-center justify-center">
                    <ArrowDropUpIcon   onClick={() => sortTableDataAsc("total")}
                    className="bg-white text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110">

                    </ArrowDropUpIcon>
                    <ArrowDropDownIcon     onClick={() => sortTableDataDesc("total")}
                    className="bg-white text-blue-600 rounded-full hover:bg-orange-600 hover:text-white hover:scale-110">
                      
                    </ArrowDropDownIcon>
                    </span> */}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white/90">
                  {<div class="excel_loder">{loading && <Loader />}</div>}
                  {sectionTotal > 0
                    ? sectionArr.map(([sectionName, data], index) => {
                      let sectionTotalMRP = 0;

                      return (
                        <>
                          {data
                            .sort((a, b) => a.area.localeCompare(b.area))
                            .map((item, itemindex) => {
                              sectionTotalMRP +=
                                item.product_attr.mrp * item.qty;

                              return data.length === 1 ? (
                                <>
                                  <tr className="section-heading">
                                    <td
                                      colSpan="15"
                                      className="border bg-gray-800 text-white text-2xl  font bold p-2 text-center"
                                    >
                                      {item.section_name}
                                    </td>
                                  </tr>
                                  <tr className="s1">
                                    <td className="border p-2 text-center">
                                      {item.sku_code}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.area?.toUpperCase() != "null" ? item.area?.toUpperCase() : ""}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.product_attr.brand}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.product_attr.product_desc}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.product_attr.finish}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.product_attr.collection}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.product_attr.product_typ_group}
                                    </td>
                                    <td className="border p-2 text-center w-24">
                                      <img
                                        src={item.product_attr.image}
                                        className="w-24"
                                      />
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.qty}
                                    </td>

                                    <td className="border p-2 text-center">
                                      {formatNumber(item.product_attr.mrp)}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {formatNumber(item.unit)}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {formatNumber(item.disc_price)}
                                    </td>
                                    <td className="border p-2 text-center w-24">
                                      {formatNumber(item.disc_per)}
                                    </td>
                                    <td className="border p-2 text-center w-24">
                                      {item.gst ? item.gst : 0}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {formatNumber(item.total)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan="15"
                                      className="border p-2 text-center text-right"
                                    >
                                      List Price :
                                      {formatNumber(
                                        item.product_attr.mrp * item.qty
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan="15"
                                      className="border p-2 text-center text-right"
                                    >
                                      Offer Price : {formatNumber(item.total)}{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan="15"
                                      className="border p-2 text-center text-right"
                                    >
                                      Shower Area Offer Price:{" "}
                                      {formatNumber(
                                        item.shower_area ? item.shower_area : ""
                                      )}{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan="15"
                                      className="border p-2 text-center text-right"
                                    >
                                      Basin Area Offer Price:{" "}
                                      {formatNumber(item.Basin)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan="15"
                                      className="border p-2 text-center text-right"
                                    >
                                      EWC Area Offer Price:{" "}
                                      {formatNumber(item.Ewc)}{" "}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colSpan="15"
                                      className="border p-2 text-center text-right"
                                    >
                                      Kitchen Area Offer Price:{" "}
                                      {formatNumber(item.kitchen)}{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan="15"
                                      className="border p-2 text-center text-right"
                                    >
                                      Faucets Area Offer Price:{" "}
                                      {formatNumber(item.faucets)}{" "}
                                    </td>
                                  </tr>
                                </>
                              ) : itemindex === 0 ? (
                                <>
                                  <tr className="section-heading">
                                    <td
                                      colSpan="15"
                                      className="border font-serif  bg-gray-800 text-white text-2xl font bold  p-2 text-center"
                                    >
                                      {item.section_name}
                                    </td>
                                  </tr>
                                  <tr className="s1">
                                    <td className="border p-2 text-center">
                                      {item.sku_code}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.area?.toUpperCase() != "null" ? item.area?.toUpperCase() : ""}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.product_attr.brand}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.product_attr.product_desc}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.product_attr.finish}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.product_attr.collection}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.product_attr.product_typ_group}
                                    </td>
                                    <td
                                      className="border p-2 text-center w-24"
                                      style={myStyles}
                                    >
                                      <img
                                        src={item.product_attr.image}
                                        className="w-24"
                                      />
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.qty}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.product_attr.mrp}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {formatNumber(item.unit)}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {formatNumber(item.disc_price)}
                                    </td>
                                    <td className="border p-2 text-center w-24">
                                      {formatNumber(item.disc_per)}
                                    </td>
                                    <td className="border p-2 text-center w-24">
                                      {item.gst ? item.gst : 0}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {formatNumber(item.total)}
                                    </td>
                                  </tr>
                                </>
                              ) : itemindex + 1 === data.length ? (
                                <>
                                  <tr className="s1">
                                    <td className="border p-2 text-center">
                                      {item.sku_code}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.area?.toUpperCase() != "null" ? item.area?.toUpperCase() : ""}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.product_attr.brand}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.product_attr.product_desc}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.product_attr.finish}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.product_attr.collection}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.product_attr.product_typ_group}
                                    </td>
                                    <td
                                      className="border p-2 text-center w-24"
                                      style={myStyles}
                                    >
                                      <img
                                        src={item.product_attr.image}
                                        className="w-24"
                                      />
                                    </td>
                                    <td className="border p-2 text-center">
                                      {item.qty}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {formatNumber(item.product_attr.mrp)}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {formatNumber(item.unit)}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {formatNumber(item.disc_price)}
                                    </td>
                                    <td className="border p-2 text-center w-24">
                                      {formatNumber(item.disc_per)}
                                    </td>
                                    <td className="border p-2 text-center w-24">
                                      {item.gst ? item.gst : 0}
                                    </td>
                                    <td className="border p-2 text-center">
                                      {formatNumber(item.total)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan="15"
                                      className="border p-2 text-center text-right"
                                    >
                                      List Price :
                                      {formatNumber(sectionTotalMRP)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan="15"
                                      className="border p-2 text-center text-right"
                                    >
                                      Offer Price : {formatNumber(item.totals)}{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan="15"
                                      className="border p-2 text-center text-right"
                                    >
                                      Shower Area Offer Price:{" "}
                                      {formatNumber(
                                        item.shower_area ? item.shower_area : ""
                                      )}{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan="15"
                                      className="border p-2 text-center text-right"
                                    >
                                      Basin Area Offer Price:{" "}
                                      {formatNumber(item.Basin)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan="15"
                                      className="border p-2 text-center text-right"
                                    >
                                      EWC Area Offer Price:{" "}
                                      {formatNumber(item.Ewc)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan="15"
                                      className="border p-2 text-center text-right"
                                    >
                                      Kitchen Area Offer Price:{" "}
                                      {formatNumber(item.kitchen)}{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colSpan="15"
                                      className="border p-2 text-center text-right"
                                    >
                                      Faucets Area Offer Price:{" "}
                                      {formatNumber(item.faucets)}{" "}
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <tr>
                                  <td className="border p-2 text-center">
                                    {item.sku_code}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.area?.toUpperCase() != "null" ? item.area?.toUpperCase() : ""}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.brand}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.product_desc}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.finish}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.collection}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.product_typ_group}
                                  </td>
                                  <td
                                    className="border p-2 text-center w-24"
                                    style={myStyles}
                                  >
                                    <img
                                      src={item.product_attr.image}
                                      className="w-24"
                                    />
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.qty}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {formatNumber(item.product_attr.mrp)}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {formatNumber(item.unit)}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {formatNumber(item.disc_price)}
                                  </td>
                                  <td className="border p-2 text-center w-24">
                                    {formatNumber(item.disc_per)}
                                  </td>
                                  <td className="border p-2 text-center w-24">
                                    {item.gst ? formatNumber(item.gst) : 0}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {formatNumber(item.total)}
                                  </td>
                                </tr>
                              );
                            })}
                        </>
                      );
                    })
                    : "No Data found"}
                </tbody>
              </table>
            </section>
          </div>
        </div>
        <div style={{ display: showComponent ? "block" : "none" }}>
          <DownloadSummary
            project={projectIdDownload}
            sendData={handleDataFromDownloadSummary}
          />
        </div>
      </MainLayout>
    </>
  );
};

export default AdminViewProject;
