import { useEffect, useState } from "react";
import UserSection from "../../Services/User/UserLogin";
import { Link } from "react-router-dom";
import UserTopbar from "../UserTopbar";
import animationData from "../../animations/loader_animation0.json";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { FaDownload, FaSearch } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa";
import DownloadSummary from "./DownloadSummary";
import ViewProjects from "./ViewProjects";
import Loader from "../utils/Loader";
import PreviewIcon from "@mui/icons-material/Preview";
import DownloadIcon from "@mui/icons-material/Download";
import { useOutletContext } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  TablePagination,
  Box,
} from "@mui/material";
import drive from "../../../src/SliderImage/drive.png";
import excelImg from "../../../src/SliderImage/excel.png";

import FileCopyIcon from "@mui/icons-material/FileCopy";
// Importing XLSX for Excel download functionality
import * as XLSX from "xlsx";
import PDF from "./PDF";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCommunicationImages,
  fetchCoverImages,
  fetchLastImages,
  fetchSummaryImages,
  fetchTechImages,
  fetchThemeImages,
} from "../../redux/slices/pdfSlice";
// import ChatIcon from "@mui/icons-material/Chat";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Summary = () => {
  const { jsPDF } = require("jspdf");
  const navigate = useNavigate();
  const [sectionArr, setSectionArr] = useState([]);
  const [projectId, setprojectId] = useState();
  const [pdfId, setpdfId] = useState();
  const [projectDatas, setprojectData] = useState();
  const [sectionTotal, setSectionTotal] = useState(0);
  const [showComponent, setshowComponent] = useState(false);
  const [showPdfComponent, setshowPdfComponent] = useState(false);
  const [pdfloading, setPdfLoading] = useState(false);
  const [excelloading, setExcelLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisable] = useState(false);
  const [dataFromDownloadSummary, setDataFromDownloadSummary] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { isSidebarOpen } = useOutletContext();

  const handleDataFromDownloadSummary = (data) => {
    setDataFromDownloadSummary(data);
    setprojectId("");
  };

  useEffect(() => {
    if (
      dataFromDownloadSummary == "downloaded" ||
      dataFromDownloadSummary == "failed"
    ) {
      setExcelLoading(false);
    }
  }, [dataFromDownloadSummary]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDataFromDownloadSummary("");
      setprojectId("");
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [projectId, dataFromDownloadSummary]);

  useEffect(() => {
    projectData();
  }, []);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const [project, setProject] = useState([
    {
      user_id: "",
      client_id: "",
      finish: "",
      area: "",
      total: "",
      project_id: "",
      client_name: "",
      phone: "",
    },
  ]);

  useEffect(() => {
    if (showComponent) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, []);

  const projectData = async () => {
    try {
      setLoading(true);
      const response = await UserSection.projectData();
      if (response.status) {
        setProject(response.data);
      }
      setLoading(false);
    } catch (error) { }
  };

  const editProject = (id) => {
    navigate(`/user/projects/${id}`);
  };

  const downloadexcel = async (id) => {
    try {
      setprojectId(id);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadpdf = async (id) => {
    try {
      navigate(`/user/viewProjects?project_id=${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredData = project.filter(
    (client) =>
      client.client_name?.toLowerCase().includes(searchQuery) ||
      String(client.project_id).toLowerCase().includes(searchQuery) ||
      String(client.client_id).toLowerCase().includes(searchQuery)
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedProjects = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Function to download Excel

  const downloadExcel = () => {
    
    const refinedData = filteredData?.map((item,index) => ({
      "S.No.": index+1,
      Client_Id: item.client_id,
      Project_Id: item.project_id,
      Valid_Date:  new Date(item.valid_date).toLocaleDateString("en-GB"),
      Created_At:   new Date(item.created_at).toLocaleDateString("en-GB"),
      Client_Name: item.client_name,
      Phone: item.phone
    }));

    const ws = XLSX.utils.json_to_sheet(refinedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Project Data");
    XLSX.writeFile(wb, "Project_Summary.xlsx");
  };

  const dispatch = useDispatch();
  const { coverImages, summaryImages, themeImages, lastImages,techImages,communicationImages, getStatus } =
    useSelector((state) => state.pdfImages);

  useEffect(() => {
    if (coverImages.length === 0) {
      dispatch(fetchCoverImages());
    }
    if (summaryImages.length === 0) {
      dispatch(fetchSummaryImages());
    }
    if (themeImages.length === 0) {
      dispatch(fetchThemeImages());
    }

    if (lastImages.length === 0) {
      dispatch(fetchLastImages());
    }
    if (techImages.length === 0) {
      dispatch(fetchTechImages());
    }
    if (communicationImages.length === 0) {
      dispatch(fetchCommunicationImages());
    }
  }, []);



  return (
    <>
      <UserTopbar heading={`Quotation Summary`} sidebar={isSidebarOpen} />
      {loading ? (
        <div className="loder">{<Loader />}</div>
      ) : (
        <>
          <Box
            className="bg-gray-900/90 p-3"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              borderColor: "#fff",
            }}
          >
            <div className="relative">
              <input
                type="text"
                onChange={handleSearchChange}
                placeholder="Search by Project-Id Client-Id Client-Name..."
                className="p-3 w-[27rem] border-2 border-teal-800 rounded-md text-black transition duration-200"
                required
              />
              <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                <FaSearch className="text-teal-800" />
              </span>
            </div>

            <span className="flex gap-6">
              <button
                variant="contained"
                color="primary"
                onClick={downloadExcel}
              >
                <img
                  src={excelImg}
                  alt="excel"
                  width={45}
                  className="transition-transform transform hover:scale-110"
                />
              </button>

              <a href="https://drive.google.com/" target="_blank">
                <img
                  src={drive}
                  alt="excel"
                  width={45}
                  className="transition-transform transform hover:scale-110"
                />
              </a>
            </span>
          </Box>

          <TableContainer>
            <Table>
              {/* Table Header */}
              <TableHead>
                <TableRow>
                  <TableCell className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}>
                    Sr.No
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Excel/PDF
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Preview
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Project ID
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Client ID
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Client Name
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    WhatsApp
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Valid Date
                  </TableCell>

                  <TableCell
                    className="bg-gray-900/90"
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              {/* Table Body */}
              <TableBody sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
                {paginatedProjects.map((row,index) => (
                  <TableRow key={row.id}>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {excelloading && <Loader />}
                        <IconButton
                          color="primary"
                          onClick={() => {
                            downloadexcel(row.project_id);
                            setExcelLoading(true);
                          }}
                        >
                          <FileCopyIcon className="cursor-pointer text-green-600 scale-125 hover:scale-150" />
                        </IconButton>
                        <PDF projectId={row.project_id} />
                      </div>
                    </TableCell>
                    {/* Project ID */}
                    <TableCell>
                      {pdfloading && row.project_id === pdfId ? (
                        <Lottie
                          animationData={animationData}
                          loop
                          autoplay
                          style={{ height: "30px", width: "30px" }}
                        />
                      ) : (
                        <IconButton
                          color="error"
                          onClick={() => {
                            downloadpdf(row.project_id);
                            localStorage.setItem("projectId", row.project_id);
                          }}
                        >
                          <PreviewIcon className="cursor-pointer text-blue-600 scale-125 hover:scale-150" />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell>{row.project_id}</TableCell>

                    {/* Client ID */}
                    <TableCell>{row.client_id}</TableCell>

                    {/* Client Name */}
                    <TableCell>{row.client_name}</TableCell>

                    {/* WhatsApp */}
                    <TableCell>
                      {row.phone ? (
                        <a
                          href={`https://web.whatsapp.com/send?phone=${row.phone}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <WhatsAppIcon className="cursor-pointer text-green-600 scale-125 hover:scale-150" />
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </TableCell>

                    {/* Date */}
                    <TableCell>
                      {new Date(row.created_at).toLocaleDateString("en-GB")}
                    </TableCell>
                    <TableCell>
                      {new Date(row.valid_date).toLocaleDateString("en-GB")}
                    </TableCell>
                    {/* Action */}
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={() => editProject(row.id)}
                        style={{
                          textTransform: "none",
                          backgroundColor: "teal",
                        }}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <TablePagination
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
            rowsPerPageOptions={[10, 50, 100, 500, filteredData.length]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      <div style={{ display: showComponent ? "block" : "none" }}>
        <DownloadSummary
          project={projectId}
          sendData={handleDataFromDownloadSummary}
        />
      </div>
    </>
  );
};

export default Summary;
