import React, { useState } from 'react';
import UserTopbar from '../UserTopbar';
import { Download } from '@mui/icons-material'; 
import Loader from '../utils/Loader';

const Manual = () => {
  const [isLoading, setIsLoading] = useState(false);
  
  const handleDownload = () => {
    setIsLoading(true);
    
    const manualUrl = "https://quotationsapis.groheapp.in/public/excel/quotation_formate.pdf"; 
    
    window.open(manualUrl, '_blank'); 
    
    setIsLoading(false);
  };
  

  return (
    <>
      <UserTopbar heading={"Manual"} />
      {isLoading && <Loader />} 
      <div className="flex justify-center items-center mt-10">
        <button
          onClick={handleDownload}
          className="flex items-center bg-gradient-to-r from-blue-500 to-green-400 text-white py-2 px-6 rounded-full shadow-lg hover:scale-105 transition-all duration-300"
        >
          <Download className="mr-3" />
          <span className="text-lg font-semibold">Download Manual</span>
        </button>
      </div>
    </>
  );
};

export default Manual;
