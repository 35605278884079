import UserSection from "../../Services/User/UserLogin";
import { useState } from "react";
import axios from "axios";
import { handleApiError } from "../../Helpers/handleApiError ";
import Swal from "sweetalert2";
import UserTopbar from "../UserTopbar";
import ApiUri from "../../Helpers/apiUrl";
function ChangePassword() {
  const [oldPassword, setOldPassword] = useState();
  const [password, setPassword] = useState();
  const [password_confirmation, setconfirmed] = useState();
  const updatePasword = async (e) => {
    e.preventDefault();
    try {
      const user = localStorage.getItem("user");
      const headers = {
        "Content-Type": "application/json", // Example header
        Authorization: "Bearer " + user, // Example Authorization header
      };
      const data = {
        oldPassword: oldPassword,
        password: password,
        password_confirmation: password_confirmation,
      };
      const response = await axios.post(`${ApiUri}change-password`,
        data,
        { headers }
      );
      if (response.status) {
        Swal.fire({
          icon: "success",
          title: "success",
          text: response.data.message,
          timer: 3000, // Automatically close after 3 seconds
        });
        window.location.href = "/";
      }
    } catch (error) {
      // console.log(error.response.data.message);
      handleApiError(error, "Failed to fetch data. Please try again later.");
    }
  };
  return (
    <>
      <UserTopbar />
      <div className="-mt-10 sm:mx-auto sm:w-full sm:max-w-sm rounded-f">
        <form className="space-y-6 change_password" action="#" method="POST">
          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block  text-white text-sm font-medium leading-6 "
              >
                Current Password
              </label>
            </div>
            <div className="mt-2">
              <input
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                id="oldPassword"
                name="oldPassword"
                type="oldPassword"
                autoComplete="current-password"
                required
                className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-white text-sm font-medium leading-6 "
              >
                New Password
              </label>
            </div>
            <div className="mt-2">
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-white text-sm font-medium leading-6 "
              >
                Confirmed New Password
              </label>
            </div>
            <div className="mt-2">
              <input
                value={password_confirmation}
                onChange={(e) => setconfirmed(e.target.value)}
                id="password_confirmation"
                name="confirmed"
                type="password"
                autoComplete="current-password"
                required
                className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <button
              onClick={updatePasword}
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
export default ChangePassword;
