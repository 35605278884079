import { useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

const QuoteGraph = ({ data, chartData }) => {
  const [barChartUrl, setBarChartUrl] = useState("");
  const [year, setYear] = useState(`${new Date().getFullYear()}`);

  const generateBarChart = (projectData) => {
    if (!Array.isArray(projectData)) {
      console.error("Invalid projectData:", projectData);
      return;
    }

    const monthlyData = new Array(12).fill(0);

    projectData.forEach((project) => {
      const projectMonth = new Date(project.created_at).getMonth(); // Get month index (0 = January, 11 = December)
      monthlyData[projectMonth] += 1; // Increment the count for the corresponding month
    });

    const config = {
      type: "bar",
      data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "Projects",
            data: monthlyData,
            backgroundColor: "#f00c2e",
          },
        ],
      },
    };

    const quickChartUrl = `https://quickchart.io/chart?c=${encodeURIComponent(
      JSON.stringify(config)
    )}`;
    setBarChartUrl(quickChartUrl);
  };

  const filteredData = useMemo(() => {
    // Convert data to an array if it's an object
    const dataArray = Array.isArray(data) ? data : Object.values(data);

    return dataArray?.filter((item) => item.created_at?.slice(0, 4) === year);
  }, [data, year]);

  useEffect(() => {
    generateBarChart(filteredData);
  }, [filteredData, year]);
  console.log(data);

  return (
    <>
      <>
        <div className="flex space-x-4 mt-6">
          {/* Projects Overview Section */}
          <div className="flex flex-col w-1/2 bg-white border border-blue-500 rounded-lg shadow-md p-4">
            <div className="flex justify-between mb-4">
              <h3 className="text-xl font-semibold">Projects Overview</h3>
              <div>
                <select
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  className="mt-1 block w-[5rem] px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="2025">2025</option>
                  <option value="2024">2024</option>
                  <option value="2023">2023</option>
                  <option value="2022">2022</option>
                  <option value="2021">2021</option>
                </select>
              </div>
            </div>
            <div className="flex-grow flex items-center justify-center">
              {barChartUrl ? (
                <img
                  src={barChartUrl}
                  alt="Bar Chart"
                  className="w-full h-[410px] object-contain" // Fixed height for the image
                />
              ) : (
                <p className="text-gray-500">Loading Bar Chart...</p>
              )}
            </div>
          </div>

          {/* Bar Chart Section */}
          <div className="flex flex-col w-1/2 bg-white border border-blue-500 rounded-lg shadow-md p-4">
            <div className="h-[410px]">
              {" "}
              {/* Fixed height for the Bar chart container */}
              <Bar
                data={chartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    title: {
                      display: true,
                      text: "Clients vs Projects",
                      font: {
                        size: 14,
                      },
                      padding: {
                        top: 3,
                        bottom: 8,
                      },
                    },
                    legend: {
                      position: "top",
                    },
                  },
                  scales: {
                    x: {
                      title: {
                        display: true,
                        text: "Categories",
                        font: {
                          size: 12,
                        },
                      },
                    },
                    y: {
                      title: {
                        display: true,
                        text: "Count",
                        font: {
                          size: 12,
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default QuoteGraph;
