import React from "react";
import { ReactTyped } from 'react-typed';
import icon from "./icon/I4.png"; // Assuming the image is correctly placed in the "icon" folder

const SmartSearchButton = () => {
    return (
        <div
            className="relative flex items-center justify-between w-56 h-12 scale-[118%] bg-white border-4 border-t-blue-500 border-r-green-500 border-b-red-600 border-l-cyan-500   font-semibold rounded-full cursor-pointer transition-all duration-500  hover:shadow-2xl hover:ring-2 hover:ring-orange-300 hover:scale-125"
        >
            {/* Image Icon */}
            <div className="flex items-center justify-center w-10 h-10 bg-gradient-to-r from-blue-600 m-[0.3px] to-teal-500 rounded-full shadow-xl transform transition-transform duration-300 ease-in-out hover:scale-125 hover:bg-blue-500">
                <img src={icon} alt="Search Icon" className="w-10 rounded-full h-10 object-contain" />
            </div>
            {/* Typing Effect */}
            <ReactTyped
                strings={["SMART FILTER", "ACACIA E","COLLECTION", "FINSHES", "TYPE", "BASIN MIXER", "ALLURE", "GROHE", "CHROME", "MATT", "BATH MIXER"]}
                typeSpeed={100}
                backSpeed={50}
                backDelay={1000}
                startDelay={500}
                loop={true}
                className="text-gray-900 text-sm  font-medium tracking-tighter pr-5"
            />


            {/* Glowing Border */}
            <div className="absolute inset-0 border-2 border-transparent rounded-full   opacity-20"></div>
        </div>
    );
};

export default SmartSearchButton;
