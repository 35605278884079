import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Apiurl from "../../Helpers/apiUrl";
import { handleApiError } from "../../Helpers/handleApiError ";
import Swal from "sweetalert2";

const getToken = () => localStorage.getItem("user");

// Fetch draft

export const fetchDraft = createAsyncThunk(
    "draft/fetchDraft",
    async (draftId, { rejectWithValue }) => {

        console.log("2")
        const token = getToken();
        if (!token) return rejectWithValue({ message: "No token found, authorization failed." });

        try {
            const response = await axios.get(`${Apiurl}project/draft/edit/${draftId}/1`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            return response.data;
        } catch (error) {
            handleApiError(error, "Please try again later.");
            return rejectWithValue(error.response?.data || { message: "Failed to fetch drafts." });
        }
    }
);
//2
export const deleteDraft = createAsyncThunk(
    "draft/deleteDraft",
    async (draftId, { rejectWithValue }) => {

        console.log("2")
        const token = getToken();
        if (!token) return rejectWithValue({ message: "No token found, authorization failed." });

        try {
            const response = await axios.get(`${Apiurl}project/draft/delete/${draftId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            return response.data;
        } catch (error) {
            handleApiError(error, "Please try again later.");
            return rejectWithValue(error.response?.data || { message: "Failed to fetch drafts." });
        }
    }
);


const initialState = {
    draftData: [],
    loading: false,
    getStatus: "idle",
    deleteStatus: "idle",
    error: null,
};

const draftSlice = createSlice({
    name: "draft",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDraft.pending, (state) => {
                state.error = null;
                state.getStatus = "pending";
            })
            .addCase(fetchDraft.fulfilled, (state, action) => {
                state.draftData = action.payload;
                state.getStatus = "succeeded";
            })
            .addCase(fetchDraft.rejected, (state, action) => {
                state.error = action.payload;
                state.getStatus = "failed";
            })
            // Updated to use unique action type 'draft/deleteDraft'
            .addCase(deleteDraft.pending, (state) => {
                state.error = null;
                state.deleteStatus = "pending";
            })
            .addCase(deleteDraft.fulfilled, (state, action) => {
                state.draftData = action.payload;
                state.deleteStatus = "succeeded";
            })
            .addCase(deleteDraft.rejected, (state, action) => {
                state.error = action.payload;
                state.deleteStatus = "failed";
            });
    },
});

export default draftSlice.reducer;
