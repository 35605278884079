import { useState, useEffect } from "react";
import Request from "../../Helpers/Request";
import { useNavigate } from "react-router-dom";
import { handleApiError } from "../../Helpers/handleApiError ";
import axios from "axios";
import Apiurl from "../../Helpers/apiUrl";
const ProtectedRoute = ({ children }) => {
  const [data, setData] = useState();
  const [login, setLogin] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = async () => {
    try {
      const user = localStorage.getItem("user");
      const headers = {
        "Content-Type": "application/json", // Example header
        Authorization: "Bearer " + user, // Example Authorization header
      };

      const response = await axios.get(
        `${Apiurl}profile`,
        { headers }
      );
      // const response = await axios.get('https://quatationapis.groheapp.in/api/profile', { headers });
      if (
        response.status &&
        response.data.data.role_id == 2 &&
        response.data.data.is_active == 1
      ) {
        setData(response.status);
      } else {
        localStorage.removeItem("user");
        navigate("/");
      }
    } catch (error) {
      // handleApiError(error, 'Failed to fetch data. Please try again later.');
      navigate("/");
    }
  };
  if (data) {
    return children;
  } else {
    navigate("/");
  }
};
export default ProtectedRoute;
