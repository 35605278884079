import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserService from "../Services/UserList";
import Swal from "sweetalert2";
import MainLayout from "./admin/MainLayout";
import Loader from "./utils/Loader";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  TablePagination,
  TextField,
  Box,
  IconButton,
  Stack,
} from "@mui/material";
import * as XLSX from "xlsx"; // Import the xlsx library
import { saveAs } from "file-saver"; // Import file-saver for saving files
import MultipleStopIcon from '@mui/icons-material/MultipleStop';

export default function AdminClientList() {
  const [users, setUsers] = useState([]); // All users fetched from API
  const [searchQuery, setSearchQuery] = useState(""); // Search input
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0); // Track current page (0-indexed)
  const [rowsPerPage, setRowsPerPage] = useState(20); // Rows per page
  const [selectedDate, setSelectedDate] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

  useEffect(() => {
    userlistapi();
  }, []);

  const userlistapi = async () => {
    try {
      setLoading(true);
      const response = await UserService.adminclient();
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: error.message,
        timer: 3000, // Automatically close after 3 seconds
      });
      setLoading(false);
    }
  };

  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filtered users based on search query
  const filteredUsers = users.filter((user) => {
    const date = new Date(user.created_at); 
    const from = fromDate ? new Date(fromDate) : null;
  const to = toDate ?new Date(new Date(toDate).setDate(new Date(toDate).getDate() + 1)) : null;

    const searchFilter =
      `${user.username} ${user.client_name} ${user.emp_code} ${user.site_add} ${user.add_to} ${user.user_id} ${user.city} ${user.state} ${user.afd} ${user.pin}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

    const matchesDate =
    (!from || date >= from) && (!to || date <= to);

    return searchFilter && matchesDate; // Return true if both conditions are satisfied
  });

  const currentUsers = filteredUsers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Function to handle Excel download
  const handleDownloadExcel = () => {
    const filteredData = filteredUsers.map((user) => ({
      id: user.id,
      username: user.username,
      emp_code: user.emp_code,
      name: user.name,
      client_name: user.client_name,
      site_add: user.site_add,
      email: user.email,
      phone: user.phone,
      add_to: user.add_to,
      user_id: user.user_id,
      city: user.city,
      pin: user.pin,
      state: user.state,
      afd: user.afd,
      created_at: new Date(user.created_at).toISOString().split("T")[0],
      updated_at: user.updated_at,
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Client");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "UserList.xlsx"); // Save the Blob as an Excel file
  };

  return (
    <MainLayout>
      {loading && <Loader />}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          // justifyContent: "space-between",
          marginBottom: 3,
          // padding: 2,
          position: "relative", // Make sure the button stays at the right
        }}
      >
        <TextField
          label="Search Client"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            width: "100%",
            maxWidth: 400,
            backgroundColor: "#f0f0f0",
            boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
            marginLeft: "20px",
          }}
        />
         <Stack direction="row" alignItems="center" spacing={1} sx={{marginLeft:7}}>
            <TextField
              label="From Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              size="small"
              sx={{
                borderRadius:'60px',
                backgroundColor: "white",
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                },
              }}
            />
            
            <IconButton disabled>
              <MultipleStopIcon  fontSize="large" className='text-green-600 bg-gray-100 rounded-full p-1 scale-75'/>
            </IconButton>
            <TextField
              label="To Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              size="small"
              sx={{
                borderRadius:'60px',
                backgroundColor: "white",
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                },
              }}
            />
          </Stack>
        <Button
          variant="contained"
          color="primary"
          style={{
            position: "absolute", // Position the button absolutely within the container
            right: "10px", // Adjust this value to position the button from the right edge
          }}
          onClick={handleDownloadExcel}
        >
          Download Excel
        </Button>
      </Box>

      <Paper>
        <TableContainer>
          <Table
            aria-label="customized table"
            sx={{ width: "100%", tableLayout: "fixed" }}
          >
            <TableHead className="bg-[#1a1a1a]">
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "white", width: "150px" }}
                >
                  SL No.
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "white", width: "150px" }}
                >
                  User Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "white", width: "150px" }}
                >
                  Emp Code
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "white", width: "150px" }}
                >
                  Client Id
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "white", width: "150px" }}
                >
                  Client Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "white", width: "150px" }}
                >
                  Billing Address
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "white", width: "150px" }}
                >
                  City
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "white", width: "150px" }}
                >
                  State
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "white", width: "150px" }}
                >
                  Pin Code
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "white", width: "150px" }}
                >
                  Architect Firm
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "white", width: "190px" }}
                >
                  Email
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "white", width: "150px" }}
                >
                  Phone
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "white", width: "150px" }}
                >
                  Site Address
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", color: "white", width: "150px" }}
                >
                  Create At
                </TableCell>

                {/* <TableCell align="center" sx={{ fontWeight: "bold", color: "white" }}>
                  Action
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentUsers.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell align="center">
                    {page * rowsPerPage + index + 1}
                  </TableCell>
                  <TableCell align="center">{item.username}</TableCell>
                  <TableCell align="center">{item.emp_code}</TableCell>
                  <TableCell align="center">{item.id}</TableCell>
                  <TableCell align="center">{item.client_name}</TableCell>
                  <TableCell align="center">{item.site_add}</TableCell>
                  <TableCell align="center">{item.city}</TableCell>
                  <TableCell align="center">{item.state}</TableCell>
                  <TableCell align="center">{item.pin}</TableCell>
                  <TableCell align="center">{item.afd}</TableCell>
                  <TableCell align="center">{item.email}</TableCell>
                  <TableCell align="center">{item.phone}</TableCell>
                  <TableCell align="center">{item.add_to}</TableCell>
                  <TableCell align="center">
                    {new Date(item.created_at).toLocaleDateString("en-GB")}
                  </TableCell>

                  {/* <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={`/admin/edit/${item.id}`}
                    >
                      Edit
                    </Button>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <TablePagination
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            marginTop: "1rem",
          }}
          rowsPerPageOptions={[10, 20, 50, 100, users.length]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </MainLayout>
  );
}
