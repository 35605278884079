import { useEffect, useMemo, useState } from "react";
import MainLayout from "./MainLayout";
import UserService from "../../Services/UserList";
import Swal from "sweetalert2";

const AdminDashboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeUserCount, setActiveUserCount] = useState(0);
  const [deactiveUserCount, setDeactiveUserCount] = useState(0);
  const [totalClient, setTotalClient] = useState(0);
  const [barChartUrl, setBarChartUrl] = useState("");
  const [pieChartUrl, setPieChartUrl] = useState("");
  const [allData, setAllData] = useState();
  const [year, setYear] = useState(`${new Date().getFullYear()}`);


  useEffect(() => {
    dashboard();
  }, []);

  useEffect(() => {
    if (activeUserCount || deactiveUserCount) {
      generatePieChart(); // Generate Pie chart only if active or deactive count is updated
    }
  }, [activeUserCount, deactiveUserCount]);

  const generateBarChart = (projectData) => {
    if (!Array.isArray(projectData)) {
      console.error("Invalid projectData:", projectData);
      return;
    }

    // Initialize an array with 12 zeros to represent each month
    const monthlyData = new Array(12).fill(0);

    // Loop through each project and increment the corresponding month
    projectData.forEach((project) => {
      const projectMonth = new Date(project.created_at).getMonth(); // Get month index (0 = January, 11 = December)
      monthlyData[projectMonth] += 1; // Increment the count for the corresponding month
    });

    const config = {
      type: "bar",
      data: {
        labels: [
          "January", "February", "March", "April", "May", "June", "July", "August",
          "September", "October", "November", "December"
        ],
        datasets: [
          {
            label: "Projects",
            data: monthlyData, // Use the monthlyData array here
            backgroundColor: "#f00c2e",
          },
        ],
      },
    };

    const quickChartUrl = `https://quickchart.io/chart?c=${encodeURIComponent(
      JSON.stringify(config)
    )}`;
    setBarChartUrl(quickChartUrl); // Update the state with the generated chart URL
  };

  const generatePieChart = () => {
    const config = {
      type: "pie",
      data: {
        labels: ["Active", "Inactive"],
        datasets: [
          {
            data: [activeUserCount, deactiveUserCount],
            backgroundColor: ["#0dc5d9", "#000"],
          },
        ],
      },
    };

    const quickChartUrl = `https://quickchart.io/chart?c=${encodeURIComponent(
      JSON.stringify(config)
    )}`;
    setPieChartUrl(quickChartUrl); // Update pie chart with the generated URL
  };

  const dashboard = async () => {
    try {
      setLoading(true);
      const response = await UserService.admindashboard();
      setLoading(false);
      setData(response.data);
      const activeUsers = response.data.all_user.filter(user => user.is_active === 1).length;
      const deactiveUsers = response.data.all_user.filter(user => user.is_active === 0).length;

      setActiveUserCount(activeUsers);
      setDeactiveUserCount(deactiveUsers);
      setAllData(response.data.project);

      // generateBarChart(response.data.project); // Pass project data for bar chart generation
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: error.message,
        timer: 3000,
      });
    }
  };

  
  const filteredData = useMemo(() => {
    return allData?.filter(item => item.created_at.slice(0, 4) === year);
  }, [allData, year]);
  
  useEffect(()=>{
    generateBarChart(filteredData);
  },[filteredData,year])
  
  return (
    <MainLayout>
      <div className="container mx-auto p-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="bg-green-100 p-6 rounded-lg shadow-lg border border-orange-600">
            <h3 className="text-xl font-semibold text-gray-800">Active Users</h3>
            <p className="mt-2 text-gray-600">Number of users currently active</p>
            <div className="mt-4">
              <span className="bg-green-300 text-green-800 px-4 py-1 rounded-full text-sm font-medium">
                {activeUserCount}
              </span>
            </div>
          </div>

          <div className="bg-yellow-100 p-6 rounded-lg shadow-lg border border-orange-600">
            <h3 className="text-xl font-semibold text-gray-800">Total Users</h3>
            <p className="mt-2 text-gray-600">Total number of users in the system.</p>
            <div className="mt-4">
              <span className="bg-yellow-300 text-yellow-800 px-4 py-1 rounded-full text-sm font-medium">
                {data.all_user ? data.all_user.length : 0}
              </span>
            </div>
          </div>

          <div className="bg-green-100 p-6 rounded-lg shadow-lg border border-orange-600">
            <h3 className="text-xl font-semibold text-gray-800">Total Client</h3>
            <p className="mt-2 text-gray-600">Total number of clients in the system.</p>
            <div className="mt-4">
              <span className="bg-green-300 text-green-800 px-4 py-1 rounded-full text-sm font-medium">
                {data.all_client ? data.all_client.length : 0}
              </span>
            </div>
          </div>

          <div className="bg-gray-100 p-6 rounded-lg shadow-lg border border-orange-600">
            <h3 className="text-xl font-semibold text-gray-800">Total Project</h3>
            <p className="mt-2 text-gray-600">Total number of projects in the system.</p>
            <div className="mt-4">
              <span className="bg-gray-300 text-gray-800 px-4 py-1 rounded-full text-sm font-medium">
                {data.project ? data.project.length : 0}
              </span>
            </div>
          </div>
        </div>

        <div className="container mx-auto  pt-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-lg border border-blue-500">
              <div className="flex  justify-between">
                <h3 className="text-xl font-semibold mb-4">Projects (Yearly)</h3>
                <div>
                  <select value={year} onChange={(e)=>setYear(e.target.value)} className="mt-1 block w-[5rem] px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option value="2025">2025</option>
                    <option value="2024">2024</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                  </select>
                </div>

              </div>
              {barChartUrl ? (
                <img src={barChartUrl} alt="Bar Chart" className="w-full" />
              ) : (
                <p>Loading Bar Chart...</p>
              )}
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg border border-blue-500">
              <h3 className="text-xl font-semibold mb-4">Active vs Inactive Users</h3>
              {pieChartUrl ? (
                <img src={pieChartUrl} alt="Pie Chart" className="w-full" />
              ) : (
                <p>Loading Pie Chart...</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default AdminDashboard;
