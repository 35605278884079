import { useEffect, useState } from "react";
import axios from "axios";
import Apiurl from "../../Helpers/apiUrl";
import DownloadIcon from "@mui/icons-material/Download";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { jsPDF } from "jspdf";
import img2 from "../../../src/clientlogo/index_grohe_logo.jpg";
import img3 from "../../../src/clientlogo/index_as_logo.gif";
import img1 from "../../../src/clientlogo/index_lixil_logo.gif";

import flatIcon from "../../clientlogo/pdf/flatIcon2.jpg";
import contract from "../../clientlogo/pdf/contract.jpg";
import CloseIcon from "@mui/icons-material/Close";
import pdfImg from "../../SliderImage/pdf.png";
import CachedIcon from "@mui/icons-material/Cached";
import bgModal from "../../clientlogo/pdf/component_background/4.jpg";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import {
  Button,
  IconButton,
  Box,
  Modal,
  Checkbox,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const tech = [
  { name: "one", image: img1 },
  { name: "two", image: contract },
  { name: "three", image: img3 },
  { name: "fpour", image: img2 },
];

const PDF = ({ projectId, swipe }) => {
  const {
    coverImages,
    summaryImages,
    themeImages,
    lastImages,
    techImages,
    communicationImages,
    getStatus,
  } = useSelector((state) => state.pdfImages);

  const [pdfHeaders, setPdfHeaders] = useState([
    "S.No",
    "SKU Code",
    "Area",
    "Brand",
    "Product Description",
    "Finish",
    "Collection",
    "Product Type",
    "Product Image",
    "QTY",
    "MRP",
    "Unit Price",
    "Discount Price",
    "Discount %",
    "GST",
    "Total",
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectDatas, setprojectData] = useState();
  const [pdfSync, setPDFSync] = useState(false);
  const [pdfTheme, setPdfTheme] = useState();
  const [pdfLastpage, setPdfLastpage] = useState();
  const [pdfCover, setPdfCover] = useState();
  const [pdfTerms, setPdfTerms] = useState();
  const [pdfSummary, setPdfSummary] = useState();
  const [pdfTech, setPdfTech] = useState([]);
  const [pdfCommunication, setPdfCommunication] = useState([]);
  const token = localStorage.getItem("user");

  // Toggle header selection
  const toggleHeader = (header) => {
    setPdfHeaders((prevHeaders) => {
      const initialOrder = [
        "S.No",
        "SKU Code",
        "Area",
        "Brand",
        "Product Description",
        "Finish",
        "Collection",
        "Product Type",
        "Product Image",
        "QTY",
        "MRP",
        "Unit Price",
        "Discount Price",
        "Discount %",
        "GST",
        "Total",
      ];

      // If the header is already in the list, remove it
      if (prevHeaders.includes(header)) {
        return prevHeaders.filter((h) => h !== header);
      }

      // If the header is not in the list, add it back at the correct position
      const updatedHeaders = [...prevHeaders, header];
      return updatedHeaders.sort(
        (a, b) => initialOrder.indexOf(a) - initialOrder.indexOf(b)
      );
    });
  };

  const formatNumber = (value) => {
    let val = value ? value : 0;

    if (typeof val === "string") {
      val = val.replace(/,/g, "");
    }

    val = parseFloat(val);

    return val.toFixed(1);
  };

  const formatNoDecimal = (value) => {
    let val = value ? value : 0;
    if (typeof val === "string") {
      val = val.replace(/,/g, "");
    }

    val = parseFloat(val);

    val = Math.round(val);

    return val;
  };

  const generatePDF = (id) => {
    setPDFSync(true);
    setprojectData();
    setIsModalOpen(true);
    imageLoad();
    return new Promise((resolve, reject) => {
      const apiUrl = `${Apiurl}project/download/${id}`;

      axios
        .get(apiUrl, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          resolve(response.data);
          setprojectData(response.data.data);
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            localStorage.removeItem("user");
          }
          reject(err);
        })
        .finally(() => {
          setPDFSync(false);
        });
    });
  };

  const { jsPDF } = require("jspdf");
  require("jspdf-autotable");

  const generatePD = () => {
    if (pdfHeaders.length > 15) {
      toast.warning(
        "Only 15 Columns are allowed, Please Remove columns which are not required"
      );
      return;
    }

    if (!pdfCover) {
      toast.warning("Select PDF Cover");
      return;
    } else if (!pdfTheme) {
      toast.warning("Select PDF Theme");
      return;
    } else if (!pdfLastpage) {
      toast.warning("Select PDF Last Page");
      return;
    } else if (!pdfSummary) {
      toast.warning("Select PDF Summary");
      return;
    }

    const doc = new jsPDF();

    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 10;
    const imgWidth = 20;
    const imgHeight = 10;
    doc.addImage(
      pdfCover,
      "JPEG",
      0,
      0,
      pageWidth,
      pageHeight,
      undefined,
      "FAST"
    );

    // Set text properties
    doc.setFont("helvetica", "bold");
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(30);
    doc.text("QUOTATION", 14, 92);

    doc.setFont("helvetica", "thin");
    doc.setFontSize(18);
    doc.text("FOR", 15, 102);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(25);
    doc.text(
      `${capitalizeFirstLetterOfEachWord(projectDatas?.client?.client_name)}`,
      32,
      102
    );

    doc.addPage();

    doc.addImage(
      pdfSummary,
      "JPEG",
      0,
      0,
      pageWidth,
      pageHeight,
      undefined,
      "FAST"
    );
    // LIXIL IMAGE
    // doc.addImage(img1, "PNG", margin, margin, imgWidth, imgHeight);     client logos

    // GROHE IMAGE
    const rightMargin = pageWidth - 60;
    // doc.addImage(img2, "PNG", rightMargin, margin, imgWidth, imgHeight);   client logos

    // flat icon to the Left-Bottom
    const imageWidth = 120;
    const imageHeight = 90;
    const imageX = margin + 5;
    const imageY = pageHeight - imageHeight - 5;
    // doc.addImage(flatIcon, "PNG", imageX, imageY, imageWidth, imageHeight);    uncomment this to for image on left bottom of summary page

    // doc.addImage(    client logos
    //   img3,
    //   "PNG",
    //   rightMargin + imgWidth + 3,
    //   margin,
    //   imgWidth,
    //   imgHeight
    // );

    doc.setFontSize(11);
    doc.setFont("helvetica", "bold");
    doc.text("GROHE INDIA PVT. LTD.", pageWidth / 2, 25, null, null, "center");
    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text("LIXIL CORPORATION", pageWidth / 2, 30, null, null, "center");
    doc.text("Quotation Letter", pageWidth / 2, 35, null, null, "center");
    //HORIZONTAL LINE  AFTER HEADERS
    // doc.setLineWidth(0.5);
    // doc.line(margin, 40, pageWidth - margin, 40);
    doc.setFontSize(10);
    // doc.setLineWidth(0.3);

    const startY = 50;
    const rowHeight = 8;

    //CLIENT DETAILS HERE
    function capitalizeFirstLetterOfEachWord(str) {
      if (!str) return ""; // Handle empty or undefined strings
      return str
        .split(" ") // Split the string into words
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ) // Capitalize the first letter of each word
        .join(" "); // Join the words back into a single string
    }

    const rows = [
      [`Client ID: ${projectDatas?.client?.id}`],
      [
        `Client Name: ${capitalizeFirstLetterOfEachWord(
          projectDatas?.client?.client_name
        )}`,
      ],
      [
        `Billing Address: ${capitalizeFirstLetterOfEachWord(
          projectDatas?.client?.site_add
        )}`,
      ],
      [
        `Architect Firm Detail: ${capitalizeFirstLetterOfEachWord(
          projectDatas?.client?.afd
        )}`,
      ],
      [
        `Site Address: ${capitalizeFirstLetterOfEachWord(
          projectDatas?.client?.add_to
        )}`,
      ],
      [`Project ID: ${projectDatas?.project_id}`],
      [
        `Date: ${new Date(projectDatas?.created_at)?.toLocaleDateString(
          "en-GB"
        )}`,
      ],
      [
        `Lixil Contact Person: ${capitalizeFirstLetterOfEachWord(
          projectDatas?.first_name
        )} ${capitalizeFirstLetterOfEachWord(projectDatas?.last_name)}`,
      ],
    ];

    doc.setFontSize(8);

    doc.autoTable({
      startY: 55,
      body: rows.map((row) => {
        const [title, value] = row[0].split(":").map((str) => str.trim());
        return [title, value];
      }),
      theme: "plain",
      styles: {
        cellPadding: 2,
        fontSize: 8,
        font: "helvetica",
        textColor: [54, 69, 79],
      },
      headStyles: {
        fillColor: [40, 116, 240],
        textColor: [255, 255, 255],
        fontStyle: "bold",
        fontSize: 10,
        halign: "center",
      },
      bodyStyles: {
        fontSize: 8,
        fontStyle: "normal",
        halign: "left",
      },
      columnStyles: {
        0: { fontStyle: "normal", cellPadding: 2, halign: "left" },
        1: { fontStyle: "normal", cellPadding: 2, halign: "right" },
      },
      rowHeight: 8,
      rowSpacing: 2,
      margin: { left: 14, right: 14 },
      tableLineWidth: 0,
      tableLineColor: [255, 255, 255],
      didDrawCell: (data) => {
        if (data.row.index % 2 === 0) {
          doc.setFillColor(17, 31, 57); // Light red for odd rows
        } else {
          doc.setFillColor(17, 31, 57); // Light green for even rows
        }

        // if (data.row.index % 2 === 0) {
        //   doc.setFillColor(24, 152, 171); // Light red for odd rows
        // } else {
        //   doc.setFillColor(24, 152, 171); // Light green for even rows
        // }
        doc.rect(
          data.cell.x,
          data.cell.y,
          data.cell.width,
          data.cell.height,
          "F"
        );

        doc.roundedRect(
          data.cell.x,
          data.cell.y,
          data.cell.width,
          data.cell.height,
          3,
          3,
          "F"
        );

        if (data.column.index === 0) {
          // Left column (title)
          doc.setTextColor(254, 254, 254); // white text
          doc.text(data.cell.text, data.cell.x + 5, data.cell.y + 6); // Draw title text
        } else if (data.column.index === 1) {
          // Right column (value)
          doc.setTextColor(254, 254, 254); // white text
          doc.text(
            data.cell.text,
            data.cell.x + data.cell.width - 5,
            data.cell.y + 6,
            { align: "right" }
          );
        }
      },
    });

    //CLIENT DETAILS END

    let yPosition = startY + rows.length * rowHeight + 5; // Adjusted to avoid overlap

    // PARTICULARS HERE

    const sectionKeys = Object.keys(projectDatas.section);
    let summaryData = [];
    let totalListPrice = 0;
    let totalOfferPrice = 0;

    sectionKeys.forEach((key, sectionIndex) => {
      const sectionData = projectDatas.section[key];
      const sectionName = `${sectionData[0].section_name}`;

      let listPrice = 0;
      let offerPrice = 0;

      sectionData.forEach((product) => {
        const qty = product.qty || 0;
        const mrp = product.product_view_attr.mrp || 0;
        const total = product.total || 0;

        listPrice += mrp * qty;
        offerPrice += total;
        totalListPrice += mrp * qty;
        totalOfferPrice += total;
        
      });

      const discountPercentage =
        listPrice > 0 ? ((listPrice - offerPrice) / listPrice) * 100 : 0;

      summaryData.push([
        sectionName,
        formatNumber(listPrice),
        formatNumber(offerPrice),
        // `${discountPercentage.toFixed(2)}%`,
      ]);
    });

    // const summaryHeaders = [
    //   ["Particulars", "MRP", "Discounted Price", "Percentage"],
    // ];
    const summaryHeaders = [["Particulars", "MRP", "Discounted Price"]];
    summaryData.push([
      "Total",
      formatNumber(totalListPrice),
      formatNumber(totalOfferPrice),
    ]);

    doc.autoTable({
      startY: yPosition,
      head: summaryHeaders,
      body: summaryData,
      theme: "grid",
      headStyles: {
        fillColor: [17, 31, 57], // Navy background
        textColor: [255, 255, 255], // White text color
        fontStyle: "bold",
        fontSize: 8,
      },
      styles: {
        halign: "center",
        valign: "middle",
        fontSize: 8,
        cellPadding: 3,
      },
      columnStyles: {
        0: { cellWidth: 60.7 }, // Fixed width for column 1
        1: { cellWidth: 60.7 }, // Fixed width for column 2
        2: { cellWidth: 60.7 }, // Fixed width for column 3
      },
      didParseCell: function (data) {
        if (data.row.index === summaryData.length - 1) { 
          data.cell.styles.fontStyle = "bold";  
          data.cell.styles.fillColor = [220, 220, 220];
        }
      },
    });
    let currentY = 60;

    if (pdfTerms) {
      //terms and condition bg image
      doc.addPage();

      doc.addImage(
        pdfTerms,
        "JPEG",
        0,
        0,
        pageWidth,
        pageHeight,
        undefined,
        "FAST"
      );

      const borderMargin = 10;

      doc.setLineWidth(0.5);
      doc.setDrawColor(150, 150, 150);
      doc.rect(
        borderMargin,
        borderMargin,
        pageWidth - 2 * borderMargin,
        pageHeight - 2 * borderMargin,
        "S"
      );

      // doc.addImage(
      //   pdfTheme,
      //   "JPEG",
      //   0,
      //   0,
      //   pageWidth,
      //   pageHeight,
      //   undefined,
      //   "FAST"
      // );

      // flat icon for terms and condition   uncomment this to add logo in terms and condition on top
      const contractWidth = 40;
      const contractHeight = 40;

      const contractX = margin + 5;
      const contractY = 6;

      // doc.addImage(
      //   contract,
      //   "PNG",
      //   contractX,
      //   contractY,
      //   contractWidth,
      //   contractHeight
      // );

      doc.setTextColor(0, 0, 0);

      let yyPosition = 30;

      // Center the "Terms and Conditions" heading
      const headingText = "Terms and Conditions";
      const headingWidth = doc.getTextWidth(headingText);
      const centerX = (pageWidth - headingWidth - 9) / 2;

      doc.setFont("helvetica", "bold");
      doc.setFontSize(12); // Adjust font size for the heading
      doc.text(headingText, centerX, yyPosition);
      yyPosition += 20;

      // Set font size and style for the terms
      doc.setFontSize(9);
      doc.setFont("helvetica", "normal");

      const terms = [
        "1. This quote is being made on behalf of the channel partner/ company as per agreement.",
        "2. This quote is valid for 60 days from the document date or until revised, amended, or modified, whichever comes first.",
        "3. Please send your written confirmation towards acceptance of quote/offer within the validity period to the channel partner.",
        "4. In case of cancellation of sale after acceptance of offer, customer shall be liable to pay cancellation fee as per below:",
        "   - 15 days prior to the date of delivery cancellation penalty will be the % of total order value.",
        "   - 30 days prior to the date of delivery cancellation penalty will be the % of total order value.",
        "   - 45 days prior to the date of delivery cancellation penalty will be the % of total order value.",
        "5. The quote can be revised, amended or modified or withdrawn at any time.",
        "6. Delivery of material shall be subject to availability of stock with the channel partner/company and the terms mutually agreed.",
        "7. Rates are delivered at site.",
        "8. Unloading of the goods to be done by the client and not by the supplier.",
        "9. The Goods once received needs to be checked and any deviation should be informed within 24 hours of delivery.",
        "10. Local levies / GST etc., are as per prevailing rates any change in the same will be applicable at the time of billing and supplies.",
        "11. Payment terms 100% advance / Local Letter of Credit / Bank Guarantee.",
        "12. Goods once sold will not be taken return, replacement will be applicable as per terms.",
        "13. The products are subject to company’s warranty policy as detailed on Company’s website for India.",
        "14. The products quoted can go under product lifecycle change and the latest/replacement product against the same will be supplied.",
        "15. All disputes arising in relation to this quote shall be subject to the jurisdiction of courts in New Delhi.",
        "16. Customer Care No : 18001024475.",
        "17. Signed ____________ ",
        `Quotation is valid for till Date - ${new Date(
          projectDatas?.valid_date
        )?.toLocaleDateString("en-GB")}`,
        "Phone No :",
        "Office Board line No",
        "Thanking you",
        "LIXIL INDIA PVT LTD (A LIXIL Corporation Company)",
        "10th floor, Baani Address One, Near Double Tree by Hilton, Golf Course Road, Sector - 56, Gurugram, Haryana - 122011",
      ];

      const lineSpacing = 4;

      terms.forEach((term, index) => {
        const xPos = borderMargin + 5;
        const maxWidth = pageWidth - 2 * (borderMargin + 5);
        const splitTerms = doc.splitTextToSize(term, maxWidth);

        splitTerms.forEach((line, lineIndex) => {
          const yPos =
            yyPosition + index * lineSpacing + lineIndex * lineSpacing;
          doc.text(line, xPos, yPos);
        });

        yyPosition += splitTerms.length * lineSpacing;
      });

      const footerYPos = pageHeight - 20;
      doc.setFontSize(8);
      doc.setTextColor(0, 0, 255);
      doc.text("grohe.co.in", borderMargin + 5, footerYPos);
      doc.link(
        borderMargin + 5,
        footerYPos - 2,
        doc.getTextWidth("grohe.co.in"),
        6,
        {
          url: "http://grohe.co.in",
        }
      );
      doc.text(
        "americanstandard.in",
        borderMargin + 5 + doc.getTextWidth("grohe.co.in") + 10,
        footerYPos
      );
      doc.link(
        borderMargin + 5 + doc.getTextWidth("grohe.co.in") + 10,
        footerYPos - 2,
        doc.getTextWidth("americanstandard.in"),
        6,
        { url: "http://americanstandard.in" }
      );
    }

    // tech / pos
    if (pdfTech.length > 0) {
      pdfTech.map((img, index) => {
        doc.addPage();
        doc.addImage(
          img.image,
          "JPEG",
          0,
          0,
          pageWidth,
          pageHeight,
          undefined,
          "FAST"
        );
      });
    }

    // communication  image
    if (pdfCommunication.length > 0) {
      pdfCommunication.map((img, index) => {
        doc.addPage();
        doc.addImage(
          img.image,
          "JPEG",
          0,
          0,
          pageWidth,
          pageHeight,
          undefined,
          "FAST"
        );
      });
    }

    const rowsPerPage = 37;

    // ALL SECTION TABLE WITH SUB TOTALS HERE  also including sorting bybrand or area
    sectionKeys.forEach((key, sectionIndex) => {
      // const sectionData = projectDatas.section[key].sort((a, b) => {
      //   const brandComparison = a.product_view_attr?.brand.localeCompare(
      //     b.product_view_attr?.brand
      //   );
      //   if (brandComparison !== 0) {
      //     return brandComparison;
      //   }
      //   return a.area.localeCompare(b.area);
      // });

      const sectionData = projectDatas?.section[key]?.sort((a, b) =>
        a.area.localeCompare(b.area)
      );
      const sectionName = `${projectDatas.section[key][0].section_name}`;

      // if (sectionIndex > 0) {
      doc.addPage();
      doc.addImage(
        pdfTheme,
        "JPEG",
        0,
        0,
        pageWidth,
        pageHeight,
        undefined,
        "FAST"
      );
      currentY = margin;
      // }

      //this adds the section name

      const sectionTitleHeight = 7;
      const sectionTitleWidth = doc.internal.pageSize.width - 2 * margin;
      doc.setFillColor(62, 68, 68);
      doc.setDrawColor(255, 255, 255);
      const textPadding = 10;
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      const textWidth = doc.getTextWidth(sectionName);
      const headerWidth = textWidth + textPadding * 2;
      const headerHeight = sectionTitleHeight;
      const borderRadius = 2;

      doc.roundedRect(
        margin + 5,
        currentY,
        headerWidth,
        headerHeight,
        borderRadius,
        borderRadius,
        "FD"
      );

      doc.setTextColor(255, 255, 255);

      const textX = margin + 5 + textPadding;
      const textY = currentY + headerHeight / 2;

      doc.text(sectionName, textX, textY, { baseline: "middle" });

      //section name end

      currentY += sectionTitleHeight + 5;

      // Prepare Table Data
      const headers = [pdfHeaders];
      const imageIndex = pdfHeaders.indexOf("Product Image");
      const skuIndex = pdfHeaders.indexOf("SKU Code");
      const collectionIndex = pdfHeaders.indexOf("Collection");
      const descIndex = pdfHeaders.indexOf("Product Description");
      const priceIndex = pdfHeaders.indexOf("Discount Price");
      const percentIndex = pdfHeaders.indexOf("Discount %");
      const type = pdfHeaders.indexOf("Product Type");
      let lessColumns = false;
      if (pdfHeaders.length <= 10) {
        lessColumns = true;
      } else {
        lessColumns = false;
      }

      const data = sectionData.map((product, index) => {
        const imageData = product?.product_view_attr?.image || "";

        const imageCell = imageData
          ? {
              image: imageData,
              width: 30,
              height: 30,
            }
          : {
              content: "No Image",
              width: 30, // Square width
              height: 30, // Square height
            };

        return [
          headers[0].includes("S.No") ? index + 1 : null,
          headers[0].includes("SKU Code") ? product.sku_code : null,
          headers[0].includes("Area")
            ? product.area === null || product.area === "null"
              ? "N/A"
              : product.area?.toUpperCase()
            : null,
          headers[0].includes("Brand")
            ? product.product_view_attr?.brand ?? null
            : null,
          headers[0].includes("Product Description")
            ? product.product_view_attr?.product_desc ?? null
            : null,
          headers[0].includes("Finish")
            ? product.product_view_attr?.finish ?? null
            : null,
          headers[0].includes("Collection")
            ? product.product_view_attr?.collection ?? null
            : null,
          headers[0].includes("Product Type")
            ? product.product_view_attr?.product_typ_group ?? null
            : null,
          headers[0].includes("Product Image") ? imageCell : null,
          headers[0].includes("QTY") ? product.qty ?? null : null,
          headers[0].includes("MRP")
            ? product.product_view_attr?.mrp !== null &&
              product.product_view_attr?.mrp !== undefined
              ? product.product_view_attr.mrp?.toFixed(0)
              : null
            : null,
          headers[0].includes("Unit Price")
            ? product.unit !== null && product.unit !== undefined
              ? formatNoDecimal(product.unit)
              : null
            : null,
          headers[0].includes("Discount Price")
            ? product.disc_price !== undefined
              ? formatNumber(product.disc_price)
              : null
            : null,
          headers[0].includes("Discount %") && product.disc_per !== undefined
            ? `${formatNumber(product.disc_per)}%`
            : null,
          headers[0].includes("GST") && product.gst !== undefined
            ? `${product.gst}%`
            : null,
          headers[0].includes("Total")
            ? product.total !== undefined
              ? formatNumber(product.total)
              : null
            : null,
        ].filter((field) => field !== null && field !== undefined);
      });

      let listPrice = 0;
      let offerPrice = 0;
      let showerAreaSubtotal = 0;
      let basinAreaSubtotal = 0;
      let ewcAreaSubtotal = 0;
      let kitchenAreaSubtotal = 0;
      let faucetsAreaSubtotal = 0;

      sectionData.forEach((product) => {
        const qty = product.qty || 0;
        const mrp = product.product_view_attr.mrp || 0;
        const total = product.total || 0;

        listPrice += mrp * qty;
        offerPrice += total;
        showerAreaSubtotal += product.shower_area || 0;
        basinAreaSubtotal = product.Basin || 0;
        ewcAreaSubtotal = product.Ewc || 0;
        kitchenAreaSubtotal = product.kitchen || 0;
        faucetsAreaSubtotal = product.faucets || 0;
      });

      const totalData = [
        [`List Price: ${formatNumber(listPrice)}`],
        [`Offer Price: ${formatNumber(offerPrice)}`],
        [`Shower Area Subtotal: ${formatNumber(showerAreaSubtotal)}`],
        [`Basin Area Subtotal: ${formatNumber(basinAreaSubtotal)}`],
        [`EWC Area Subtotal: ${formatNumber(ewcAreaSubtotal)}`],
        [`Kitchen Area Subtotal: ${formatNumber(kitchenAreaSubtotal)}`],
        [`Faucets Area Subtotal: ${formatNumber(faucetsAreaSubtotal)}`],
      ];

      const squareSize = 13;
      const smallWidth = 15;

      let rowCount = 0; // Counter for rows
      currentY = 20; // Adjust based on your starting position

      // Split data into chunks of 18 rows per page
      const chunkedData = [];
      while (data.length > 0) {
        chunkedData.push(data.splice(0, 18));
      }

      chunkedData.forEach((chunk, index) => {
        doc.autoTable({
          startY: currentY,
          head: headers,
          body: chunk,
          columnStyles: {
            [skuIndex]: { cellWidth: lessColumns ? 23 : 15 },
            [imageIndex]: { cellWidth: squareSize },
            [collectionIndex]: { cellWidth: lessColumns ? 30 : 17 },
            [descIndex]: { cellWidth: lessColumns ? 42 : 30 },
            [percentIndex]: { cellWidth: lessColumns ? 17 : 11 },
            [type]: { cellWidth: lessColumns ? 20 : 13 },
          },
          didDrawCell: (data) => {
            // Handle the image column (index 8)
            if (
              data.column.index === imageIndex &&
              typeof data.cell.raw === "object"
            ) {
              const { image } = data.cell.raw;

              // Ensure row height matches square size
              data.row.height = squareSize;

              // Add the image to the cell (centered)
              doc.addImage(
                image, // Base64 or URL
                "JPEG", // Image format
                data.cell.x + 1,
                data.cell.y + 1,
                squareSize - 2,
                squareSize - 2
              );
            }
          },
          theme: "grid",
          headStyles: {
            fillColor: [17, 31, 57],
            textColor: [255, 255, 255],
            fontSize: 4,
            fontStyle : "bold",
          },

          styles: {
            halign: "center",
            valign: "middle",
            fontSize: 5,
            fontStyle : "bold",
            minCellHeight: squareSize,
          },
          alternateRowStyles: {
            fillColor: [254, 254, 254],
          },
        });

        // If it's not the last chunk, add a page break
        if (index < chunkedData.length - 1) {
          doc.addPage();
          doc.addImage(
            pdfTheme,
            "JPEG",
            0,
            0,
            pageWidth,
            pageHeight,
            undefined,
            "FAST"
          );
          currentY = 20; // Reset Y position for the new page
        }
      });

      // sub-total total rows after the section data end

      let y = doc.autoTable.previous.finalY + 8;
      const rowHeight = 11;
      const rowSpacing = 5;
      const marginLeft = 15;
      const rowWidth = 180;

      const rowBgColors = {
        listPrice: [3, 79, 132],
        offerPrice: [242, 240, 233],
        other: [199, 197, 191],
      };
      const rowtextColors = {
        listPrice: [254, 254, 254], // White
        other: [0, 0, 0], // Black
      };

      const borderColor = [240, 240, 240];

      const validData = totalData.filter((row) => {
        const value = row[0].split(":")[1].trim();
        return value !== "0" && value !== "null" && value !== "";
      });

      // total height for valid rows only
      const totalHeight = validData.length * (rowHeight + rowSpacing);

      // Check if there's enough space for all rows
      if (y + totalHeight > pageHeight) {
        doc.addPage();
        doc.addImage(
          pdfTheme,
          "JPEG",
          0,
          0,
          pageWidth,
          pageHeight,
          undefined,
          "FAST"
        );
        y = 20; // Reset y to top of the new page
        y += 20; // Add extra space from the top of the page if needed
      }

      totalData.forEach((row, index) => {
        const [title, value] = row[0].split(":").map((str) => str.trim());
        if (value === "0" || value === "null" || value === "") {
          return;
        }

        let backgroundColor;
        let textColor;
        let addBorder = false;

        if (title.includes("List Price")) {
          backgroundColor = rowBgColors.listPrice;
        } else if (title.includes("Offer Price")) {
          backgroundColor = rowBgColors.offerPrice;
        } else {
          backgroundColor = rowBgColors.other;
        }

        if (title.includes("List Price")) {
          textColor = rowtextColors.listPrice;
        } else {
          textColor = rowtextColors.other;
        }

        if (title.includes("List Price")) {
          addBorder = true;
        } else {
          textColor = rowtextColors.other;
          addBorder = false;
        }

        if (addBorder) {
          doc.setDrawColor(borderColor[0], borderColor[1], borderColor[2]);
        }

        doc.setFillColor(...backgroundColor);
        doc.roundedRect(marginLeft, y, rowWidth, rowHeight, 3, 3, "F");
        doc.setTextColor(textColor[0], textColor[1], textColor[2]);
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        doc.text(title, marginLeft + 5, y + 7);
        doc.text(value, marginLeft + rowWidth - 5, y + 7, { align: "right" });
        y += rowHeight + rowSpacing;
      });

      // Update currentY for the next section
      currentY = doc.autoTable.previous.finalY + 10;

      // Add a new page if there's not enough space for the next section
      if (currentY + 10 > doc.internal.pageSize.height - margin) {
        doc.addPage();
        doc.addImage(
          pdfTheme,
          "JPEG",
          0,
          0,
          pageWidth,
          pageHeight,
          undefined,
          "FAST"
        );
        currentY = margin;
      }
    });
    doc.addPage();
    doc.addImage(
      pdfLastpage,
      "JPEG",
      0,
      0,
      pageWidth,
      pageHeight,
      undefined,
      "FAST"
    );

    // const pageCount = doc.getNumberOfPages(); // Get the total number of pages

    // // Loop through all pages, skipping the first, third, and last pages
    // for (let i = 1; i <= pageCount; i++) {
    //   if (i === 1 || i === pageCount) {
    //     continue; // Skip these pages
    //   }

    //   doc.setPage(i); // Set the current page

    //   // Save the current graphics state
    //   doc.setGState(new doc.GState({ opacity: 0.5 })); // Adjust opacity if needed (e.g., 0.3 for light background)

    //   // Add the image as a background
    //   doc.addImage(
    //     defPage, // The image source (Base64, URL, etc.)
    //     "JPEG", // Image format
    //     0, // X position (top-left corner)
    //     0, // Y position (top-left corner)
    //     pageWidth, // Width of the image (same as the page width)
    //     pageHeight // Height of the image (same as the page height)
    //   );

    //   // Restore the graphics state (ensures opacity doesn't affect other elements)
    //   doc.setGState(new doc.GState({ opacity: 1 }));
    // }

    // Save the PDF
    doc.save(`Quotation_${projectDatas?.project_id}.pdf`);
    setIsModalOpen(false);
  };

  const handleSelectTech = (image) => {
    setPdfTech((prev) => {
      const index = prev.findIndex((item) => item.image === image.image);
      if (index !== -1) {
        return prev.filter((item) => item.image !== image.image);
      } else {
        return [...prev, { ...image, number: prev.length + 1 }];
      }
    });
  };
  const handleSelectCommunication = (image) => {
    setPdfCommunication((prev) => {
      const index = prev.findIndex((item) => item.image === image.image);
      if (index !== -1) {
        return prev.filter((item) => item.image !== image.image);
      } else {
        return [...prev, { ...image, number: prev.length + 1 }];
      }
    });
  };
  const placeholder =
    "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";

  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(false);
  const [five, setFive] = useState(false);
  const [six, setSix] = useState(false);

  const imageLoad = () => {
    const stateSetters = [setOne, setTwo, setThree, setFour, setFive, setSix];
    const setStatesWithDelay = (index) => {
      if (index < stateSetters.length) {
        stateSetters[index](true);
        setTimeout(() => setStatesWithDelay(index + 1), 1000);
      }
    };

    setStatesWithDelay(0);
  };

  const resetStates = () => {
    setOne(false);
    setTwo(false);
    setThree(false);
    setFour(false);
    setFive(false);
    setSix(false);
  };
  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={true}
        theme="dark"
      />
      <Modal
        open={isModalOpen}

        //  onClose={() => setIsModalOpen(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "95vw",
            maxHeight: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,

            borderRadius: 2,
            overflowY: "auto",
            border: "2px solid #2563eb",
          }}
          style={{
            // backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.7)), url(${bgModal})`,
            backgroundSize: "cover",
            backgroundColor: "#fff",
            backgroundPosition: "center",
          }}
        >
          {/* Close Button */}
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsModalOpen(false);
              resetStates();
            }}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "#374151",
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Modal Title */}
          <Typography
            component="h2"
            mb={3}
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              color: "#2563eb",
              textTransform: "uppercase",
            }}
          >
            Select Columns for PDF
          </Typography>

          {/* Column Selection */}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
              gap: 2,
              marginBottom: 3,
            }}
          >
            {[
              "S.No",
              "SKU Code",
              "Area",
              "Brand",
              "Product Description",
              "Finish",
              "Collection",
              "Product Type",
              "Product Image",
              "QTY",
              "MRP",
              "Unit Price",
              "Discount Price",
              "Discount %",
              "GST",
              "Total",
            ].map((header, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  backgroundColor: "#bbeefc",
                  p: 1,
                  borderRadius: 1,
                  transition: "background-color 0.3s",
                  "&:hover": { backgroundColor: "#ebe65b" },
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Checkbox
                  id={`checkbox-${index}`}
                  checked={pdfHeaders.includes(header)}
                  onChange={() => toggleHeader(header)}
                  color="primary"
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 22,
                    },
                  }}
                />
                <label
                  htmlFor={`checkbox-${index}`}
                  style={{
                    cursor: "pointer",
                    fontSize: "14px",
                    color: "#374151",
                  }}
                >
                  {header}
                </label>
              </Box>
            ))}
          </Box>

          {/* Cover Page Selection */}
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#2563eb",
              marginBottom: 1,
              textTransform: "uppercase",
            }}
          >
            Select Cover Page{" "}
            <span className="text-red-500 ml-4 text-lg">*</span>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              marginBottom: 3,
              // justifyContent: "center", // Centers cards within the container
            }}
          >
            {coverImages.length > 0 && one ? (
              <>
                {coverImages?.map((image, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                      // backgroundColor:
                      //   pdfCover === image.image ? "#fff" : "#ebe65b",
                      border:
                        pdfCover === image.image
                          ? "2px solid #2563eb"
                          : "2px solid #ccc",
                      padding: 0.5,
                      paddingX: 0,
                      borderRadius: 1,
                      transition: "border-color 0.3s",
                    }}
                    onClick={() => setPdfCover(image.image)}
                  >
                    <img
                      src={image.image || placeholder}
                      alt={`Cover ${index}`}
                      style={{
                        width: "210px",
                        height: "210px",
                        objectFit: "contain",
                        borderRadius: "5px",
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        mt: 1,
                        color: pdfCover === image.image ? "#2563eb" : "#374151",
                      }}
                    >
                      <span className="text-orange-600   bg-white p-1 text-[10px] rounded-full">
                        {" "}
                        {image.name}
                      </span>
                    </Typography>
                  </Box>
                ))}
              </>
            ) : (
              <CachedIcon fontSize="large" className="animate-spin  ml-3" />
            )}
          </Box>
          {/* summary Page Selection */}
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#2563eb",
              marginBottom: 1,
              textTransform: "uppercase",
            }}
          >
            Select Summary Page{" "}
            <span className="text-red-500 ml-4 text-lg">*</span> 
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              marginBottom: 3,
              // justifyContent: "center", // Centers cards within the container
            }}
          >
            {summaryImages.length > 0 && two ? (
              <>
                {summaryImages?.map((image, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                      // backgroundColor:
                      //   pdfSummary === image.image ? "#fff" : "#9db5ed",
                      border:
                        pdfSummary === image.image
                          ? "2px solid #2563eb"
                          : "2px solid #ccc",
                      padding: 0.5,
                      paddingX: 0,
                      borderRadius: 1,
                      transition: "border-color 0.3s",
                    }}
                    onClick={() => setPdfSummary(image.image)}
                  >
                    <img
                      src={image.image || placeholder}
                      alt={`Cover ${index}`}
                      style={{
                        width: "210px",
                        height: "210px",
                        objectFit: "contain",
                        borderRadius: "5px",
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        mt: 1,
                        color:
                          pdfSummary === image.image ? "#2563eb" : "#374151",
                      }}
                    >
                      <span className="text-orange-600   bg-white p-1 text-[10px] rounded-full">
                        {" "}
                        {image.name}
                      </span>
                    </Typography>
                  </Box>
                ))}
              </>
            ) : (
              <CachedIcon fontSize="large" className="animate-spin ml-3" />
            )}
          </Box>
          {/* Terms Page Selection */}
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#2563eb",
              marginBottom: 1,
              textTransform: "uppercase",
            }}
          >
            Select Terms & Conditions{" "}
            {/* <span className="text-red-500 ml-4 text-lg">*</span> */}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              marginBottom: 3,
              // justifyContent: "center", // Centers cards within the container
            }}
          >
            {summaryImages.length > 0 && two ? (
              <>
                {summaryImages?.map((image, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                      // backgroundColor:
                      //   pdfTerms === image.image ? "#fff" : "#9db5ed",
                      border:
                        pdfTerms === image.image
                          ? "2px solid #2563eb"
                          : "2px solid #ccc",
                      padding: 0.5,
                      paddingX: 0,
                      borderRadius: 1,
                      transition: "border-color 0.3s",
                    }}
                    onClick={() => setPdfTerms(image.image)}
                  >
                    <img
                      src={image.image || placeholder}
                      alt={`Cover ${index}`}
                      style={{
                        width: "210px",
                        height: "210px",
                        objectFit: "contain",
                        borderRadius: "5px",
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        mt: 1,
                        color: pdfTerms === image.image ? "#2563eb" : "#374151",
                      }}
                    >
                      <span className="text-orange-600   bg-white p-1 text-[10px] rounded-full">
                        {" "}
                        {image.name}
                      </span>
                    </Typography>
                  </Box>
                ))}
              </>
            ) : (
              <CachedIcon fontSize="large" className="animate-spin ml-3" />
            )}
          </Box>

          {/* Technology / MOT's Selection */}
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#2563eb",
              marginBottom: 1,
              textTransform: "uppercase",
            }}
          >
            Select Technology / MOT's{" "}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              marginBottom: 3,
              // justifyContent: "center", // Centers cards within the container
            }}
          >
            {techImages.length > 0 && three ? (
              techImages.map((image, index) => {
                const selectedIndex = pdfTech.findIndex(
                  (item) => item.image === image.image
                );
                const isSelected = selectedIndex !== -1;

                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                      // backgroundColor: isSelected ? "#fff" : "#a583cc",
                      border: isSelected
                        ? "2px solid #2563eb"
                        : "2px solid #ccc",
                      padding: 0.5,
                      borderRadius: 1,
                      transition: "border-color 0.3s",
                      position: "relative", // To position badge correctly
                    }}
                    onClick={() => handleSelectTech(image)}
                  >
                    {/* Selection Number Badge */}
                    {isSelected && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: -5,
                          right: -5,
                          backgroundColor: "#2563eb",
                          color: "white",
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        {selectedIndex + 1}
                      </Box>
                    )}

                    <img
                      src={image.image}
                      alt={`Cover ${index}`}
                      style={{
                        width: "210px",
                        height: "210px",
                        objectFit: "contain",
                        borderRadius: "5px",
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        mt: 1,
                        color: isSelected ? "#2563eb" : "#374151",
                      }}
                    >
                      <span className="text-orange-600 bg-white p-1 text-[10px] rounded-full">
                        {image.name}
                      </span>
                    </Typography>
                  </Box>
                );
              })
            ) : (
              <CachedIcon fontSize="large" className="animate-spin ml-3" />
            )}
          </Box>

          {/* Push Communication Selection */}
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#2563eb",
              marginBottom: 1,
              textTransform: "uppercase",
            }}
          >
            Select Push Communication{" "}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              marginBottom: 3,
              // justifyContent: "center", // Centers cards within the container
            }}
          >
            {communicationImages.length > 0 && four ? (
              communicationImages.map((image, index) => {
                const selectedIndex = pdfCommunication.findIndex(
                  (item) => item.image === image.image
                );
                const isSelected = selectedIndex !== -1;

                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                      // backgroundColor: isSelected ? "#fff" : "#e87f6f",
                      border: isSelected
                        ? "2px solid #2563eb"
                        : "2px solid #ccc",
                      padding: 0.5,
                      borderRadius: 1,
                      transition: "border-color 0.3s",
                      position: "relative", // To position badge correctly
                    }}
                    onClick={() => handleSelectCommunication(image)}
                  >
                    {/* Selection Number Badge */}
                    {isSelected && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: -5,
                          right: -5,
                          backgroundColor: "#2563eb",
                          color: "white",
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        {selectedIndex + 1}
                      </Box>
                    )}

                    <img
                      src={image.image || placeholder}
                      alt={`Cover ${index}`}
                      style={{
                        width: "210px",
                        height: "210px",
                        objectFit: "contain",
                        borderRadius: "5px",
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        mt: 1,
                        color: isSelected ? "#2563eb" : "#374151",
                      }}
                    >
                      <span className="text-orange-600 bg-white p-1 text-[10px] rounded-full">
                        {image.name}
                      </span>
                    </Typography>
                  </Box>
                );
              })
            ) : (
              <CachedIcon fontSize="large" className="animate-spin ml-3" />
            )}
          </Box>

          {/* Theme Selection */}
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#2563eb",
              marginBottom: 1,
              textTransform: "uppercase",
            }}
          >
            Select Product Selection{" "}
            <span className="text-red-500 ml-4 text-lg">*</span>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              marginBottom: 3,
              // justifyContent: "center", // Centers cards within the container
            }}
          >
            {themeImages.length > 0 && five ? (
              <>
                {themeImages?.map((image, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                      // backgroundColor:
                      //   pdfTheme === image.image ? "#fff" : "#9fe35f",
                      border:
                        pdfTheme === image.image
                          ? "2px solid #2563eb"
                          : "2px solid #ccc",
                      padding: 0.5,
                      paddingX: 0,
                      borderRadius: 1,
                      transition: "border-color 0.3s",
                    }}
                    onClick={() => setPdfTheme(image.image)}
                  >
                    <img
                      src={image.image || placeholder}
                      alt={`Theme ${index}`}
                      style={{
                        width: "210px",
                        height: "210px",
                        objectFit: "contain",
                        borderRadius: "5px",
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        mt: 1,
                        color: pdfTheme === image.image ? "#2563eb" : "#374151",
                      }}
                    >
                      <span className="text-orange-600   bg-white p-1 text-[10px] rounded-full">
                        {" "}
                        {image.name}
                      </span>
                    </Typography>
                  </Box>
                ))}
              </>
            ) : (
              <CachedIcon fontSize="large" className="animate-spin ml-3" />
            )}
          </Box>
          {/* Last Page Selection */}
          <Typography
            sx={{
              fontWeight: "bold",
              color: "#2563eb",
              marginBottom: 1,
              textTransform: "uppercase",
            }}
          >
            Select Last Page{" "}
            <span className="text-red-500 ml-4 text-lg">*</span>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              marginBottom: 3,
              // justifyContent: "center", // Centers cards within the container
            }}
          >
            {lastImages.length > 0 && six ? (
              <>
                {lastImages?.map((image, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                      // backgroundColor:
                      //   pdfLastpage === image.image ? "#fff" : "#7ca3a6",
                      border:
                        pdfLastpage === image.image
                          ? "2px solid #2563eb"
                          : "2px solid #ccc",
                      padding: 0.5,
                      paddingX: 0,
                      borderRadius: 1,
                      transition: "border-color 0.3s",
                    }}
                    onClick={() => setPdfLastpage(image.image)}
                  >
                    <img
                      src={image.image || placeholder}
                      alt={`Theme ${index}`}
                      style={{
                        width: "210px",
                        height: "210px",
                        objectFit: "contain",
                        borderRadius: "5px",
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        mt: 1,
                        color:
                          pdfLastpage === image.image ? "#2563eb" : "#374151",
                      }}
                    >
                      <span className="text-orange-600   bg-white p-1 text-[10px] rounded-full">
                        {" "}
                        {image.name}
                      </span>
                    </Typography>
                  </Box>
                ))}
              </>
            ) : (
              <CachedIcon fontSize="large" className="animate-spin ml-3" />
            )}
          </Box>
          <span className="flex justify-between ">
            {/* Download Button */}
            <Button
              variant="contained"
              onClick={() => generatePD()}
              disabled={pdfSync}
              sx={{
                mt: "50px",
                py: 1,
                fontWeight: "bold",
                fontSize: "16px",
                backgroundColor: "#2563eb",
                color: "#fff",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "200px",
                margin: "0 auto",
                "&:hover": {
                  backgroundColor: "#1d4ed8",
                  boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                },
                "&:disabled": {
                  backgroundColor: "#b3c7f9",
                  boxShadow: "none",
                },
              }}
            >
              <DownloadIcon sx={{ mr: 1 }} />
              {pdfSync ? "Syncing..." : "Download"}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setIsModalOpen(false);
                resetStates();
              }}
              sx={{
                mt: "50px",
                py: 1,
                fontWeight: "bold",
                fontSize: "16px",
                backgroundColor: "#d92b2b",
                color: "#fff",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "200px",
                margin: "0 auto",
                "&:hover": {
                  backgroundColor: "#c95353",
                  boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                },
              }}
            >
              <HighlightOffIcon sx={{ mr: 1 }} />
              Cancel
            </Button>
          </span>
        </Box>
      </Modal>
      {!swipe ? (
        <IconButton
          color="primary"
          onClick={() => {
            generatePDF(projectId);
          }}
        >
          <PictureAsPdfIcon className="cursor-pointer text-red-600 scale-125 hover:scale-150" />
        </IconButton>
      ) : (
        <button
          onClick={() => {
            generatePDF(projectId);
          }}
        >
          <img
            src={pdfImg}
            alt="pdf"
            width={45}
            className="transition-transform transform hover:scale-110"
          />
        </button>
      )}
    </>
  );
};
export default PDF;
