import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Apiurl from "../../Helpers/apiUrl";
import { handleApiError } from "../../Helpers/handleApiError ";
import Swal from "sweetalert2";

const getToken = () => localStorage.getItem("user");

// Fetch Cover Images
export const fetchCoverImages = createAsyncThunk(
    "images/fetchCoverImages",
    async (_, { rejectWithValue }) => {
        const token = getToken();
        if (!token) return rejectWithValue({ message: "No token found, authorization failed." });

        try {
            const response = await axios.get(`${Apiurl}admin/cover-images`, {
                headers: { Authorization: `Bearer ${token}` },
            });


            return response.data;
        } catch (error) {
            handleApiError(error, "Please try again later.");
            return rejectWithValue(error.response?.data || { message: "Failed to fetch cover images." });
        }
    }
);

// Fetch Summary Images
export const fetchSummaryImages = createAsyncThunk(
    "images/fetchSummaryImages",
    async (_, { rejectWithValue }) => {
        const token = getToken();
        if (!token) return rejectWithValue({ message: "No token found, authorization failed." });

        try {
            const response = await axios.get(`${Apiurl}admin/summary-images`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            handleApiError(error, "Please try again later.");
            return rejectWithValue(error.response?.data || { message: "Failed to fetch summary images." });
        }
    }
);

// Fetch Theme Images
export const fetchThemeImages = createAsyncThunk(
    "images/fetchThemeImages",
    async (_, { rejectWithValue }) => {
        const token = getToken();
        if (!token) return rejectWithValue({ message: "No token found, authorization failed." });

        try {
            const response = await axios.get(`${Apiurl}admin/theme-images`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            return response.data;
        } catch (error) {
            handleApiError(error, "Please try again later.");
            return rejectWithValue(error.response?.data || { message: "Failed to fetch theme images." });
        }
    }
);

// Fetch Last Images
export const fetchLastImages = createAsyncThunk(
    "images/fetchLastImages",
    async (_, { rejectWithValue }) => {
        const token = getToken();
        if (!token) return rejectWithValue({ message: "No token found, authorization failed." });

        try {
            const response = await axios.get(`${Apiurl}admin/last-images`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            return response.data;
        } catch (error) {
            handleApiError(error, "Please try again later.");
            return rejectWithValue(error.response?.data || { message: "Failed to fetch last images." });
        }
    }
);
export const fetchTechImages = createAsyncThunk(
    "images/fetchTechImages",
    async (_, { rejectWithValue }) => {
        const token = getToken();
        if (!token) return rejectWithValue({ message: "No token found, authorization failed." });

        try {
            const response = await axios.get(`${Apiurl}admin/technology`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            return response.data;
        } catch (error) {
            handleApiError(error, "Please try again later.");
            return rejectWithValue(error.response?.data || { message: "Failed to fetch last images." });
        }
    }
);
export const fetchCommunicationImages = createAsyncThunk(
    "images/fetchCommunicationImages",
    async (_, { rejectWithValue }) => {
        const token = getToken();
        if (!token) return rejectWithValue({ message: "No token found, authorization failed." });

        try {
            const response = await axios.get(`${Apiurl}admin/communication`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            return response.data;
        } catch (error) {
            handleApiError(error, "Please try again later.");
            return rejectWithValue(error.response?.data || { message: "Failed to fetch last images." });
        }
    }
);

// Add Cover Image
export const addCoverImage = createAsyncThunk(
    "images/addCoverImage",
    async ({ image, name }, { rejectWithValue }) => {
        console.log(image)
        const token = getToken();
        if (!token) return rejectWithValue({ message: "No token found, authorization failed." });

        const formData = new FormData();
        formData.append("image", image);
        formData.append("name", name);

        try {
            const response = await axios.post(`${Apiurl}admin/cover-images/add`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            Swal.fire({
                icon: "success",
                title: "Cover Page",
                text: "Added  successfully",
                timer: 3000,
            });
            return response.data;
        } catch (error) {
            handleApiError(error, "Failed to add. Please try again later.");
            return rejectWithValue(error.response?.data || { message: "Failed to add cover image." });
        }
    }
);

// Add Summary Image
export const addSummaryImage = createAsyncThunk(
    "images/addSummaryImage",
    async ({ image, name }, { rejectWithValue }) => {
        const token = getToken();
        if (!token) return rejectWithValue({ message: "No token found, authorization failed." });

        const formData = new FormData();
        formData.append("image", image);
        formData.append("name", name);

        try {
            const response = await axios.post(`${Apiurl}admin/summary-images/add`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            Swal.fire({
                icon: "success",
                title: "Summary Image",
                text: "Added  successfully",
                timer: 3000,
            });
            return response.data;
        } catch (error) {
            handleApiError(error, "Failed to add. Please try again later.");
            return rejectWithValue(error.response?.data || { message: "Failed to add summary image." });
        }
    }
);

// Add Theme Image
export const addThemeImage = createAsyncThunk(
    "images/addThemeImage",
    async ({ image, name }, { rejectWithValue }) => {
        const token = getToken();
        if (!token) return rejectWithValue({ message: "No token found, authorization failed." });

        const formData = new FormData();
        formData.append("image", image);
        formData.append("name", name);

        try {
            const response = await axios.post(`${Apiurl}admin/theme-images/add`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            Swal.fire({
                icon: "success",
                title: "Theme Image",
                text: "Added  successfully",
                timer: 3000,
            });
            return response.data;
        } catch (error) {
            handleApiError(error, "Failed to add. Please try again later.");
            return rejectWithValue(error.response?.data || { message: "Failed to add theme image." });
        }
    }
);

// Add Last Image
export const addLastImage = createAsyncThunk(
    "images/addLastImage",
    async ({ image, name }, { rejectWithValue }) => {
        const token = getToken();
        if (!token) return rejectWithValue({ message: "No token found, authorization failed." });

        const formData = new FormData();
        formData.append("image", image);
        formData.append("name", name);

        try {
            const response = await axios.post(`${Apiurl}admin/last-images/add`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            Swal.fire({
                icon: "success",
                title: "Last Page",
                text: "Added  successfully",
                timer: 3000,
            });
            return response.data;
        } catch (error) {
            handleApiError(error, "Failed to add. Please try again later.");
            return rejectWithValue(error.response?.data || { message: "Failed to add last image." });
        }
    }
);
export const addCommunicationImage = createAsyncThunk(
    "images/addCommunicationImage",
    async ({ image, name }, { rejectWithValue }) => {
        const token = getToken();
        if (!token) return rejectWithValue({ message: "No token found, authorization failed." });

        const formData = new FormData();
        formData.append("image", image);
        formData.append("name", name);

        try {
            const response = await axios.post(`${Apiurl}admin/communication/add`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            Swal.fire({
                icon: "success",
                title: "Push Communication",
                text: "Added  successfully",
                timer: 3000,
            });
            return response.data;
        } catch (error) {
            handleApiError(error, "Failed to add. Please try again later.");
            return rejectWithValue(error.response?.data || { message: "Failed to add last image." });
        }
    }
);
export const addTechImage = createAsyncThunk(
    "images/techImage",
    async ({ image, name }, { rejectWithValue }) => {
        const token = getToken();
        if (!token) return rejectWithValue({ message: "No token found, authorization failed." });

        const formData = new FormData();
        formData.append("image", image);
        formData.append("name", name);

        try {
            const response = await axios.post(`${Apiurl}admin/technology/add`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            Swal.fire({
                icon: "success",
                title: "Technology / Mot's",
                text: "Added  successfully",
                timer: 3000,
            });
            return response.data;
        } catch (error) {
            handleApiError(error, "Failed to add. Please try again later.");
            return rejectWithValue(error.response?.data || { message: "Failed to add last image." });
        }
    }
);

// Delete Image
export const deleteImageThunk = createAsyncThunk(
    "images/deleteImage",
    async ({ id, type }, { rejectWithValue }) => {
        const token = getToken();
        if (!token) {
            return rejectWithValue({ message: "Authentication failed, no token found." });
        }

        const endpointMap = {
            cover: "admin/cover-images/delete",
            summary: "admin/summary-images/delete",
            theme: "admin/theme-images/delete",
            last: "admin/last-images/delete",
            tech: "admin/technology/delete",
            communication: "admin/communication/delete",
        };

        const endpoint = endpointMap[type];
        if (!endpoint) {
            return rejectWithValue({ message: "Invalid image type provided." });
        }

        const url = `${Apiurl}${endpoint}/${id}`;
        try {
            await axios.get(url, {
                headers: { Authorization: `Bearer ${token}` },
            });
            Swal.fire({
                icon: "success",
                title: "",
                text: "Deleted  successfully",
                timer: 3000,
            });
            return { id, type };
        } catch (error) {
            handleApiError(error, "Failed to delete. Please try again later.");
            return rejectWithValue(error.response?.data || { message: "Failed to delete image." });
        }
    }
);


// Initial state
const initialState = {
    coverImages: [],
    summaryImages: [],
    themeImages: [],
    lastImages: [],
    techImages: [],
    communicationImages: [],
    loading: false,
    addStatus: "idle",
    getStatus: "idle",
    deleteStatus: "idle",
    error: null,
};

const imageSlice = createSlice({
    name: "images",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCoverImages.pending, (state, action) => {
                state.loading = true;
                state.error = null;
                state.getStatus = "pending";
            })
            .addCase(fetchCoverImages.fulfilled, (state, action) => {
                state.coverImages = action.payload.data;
                state.loading = false;
                state.getStatus = "succeeded";
            })
            .addCase(fetchCoverImages.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
                state.getStatus = "failed";
            });

        builder
            .addCase(fetchSummaryImages.pending, (state, action) => {
                state.loading = true;
                state.error = null;
                state.getStatus = "pending";
            })
            .addCase(fetchSummaryImages.fulfilled, (state, action) => {
                state.summaryImages = action.payload.data;
                state.loading = false;
                state.getStatus = "succeeded";
            })
            .addCase(fetchSummaryImages.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
                state.getStatus = "failed";
            });

        builder
            .addCase(fetchThemeImages.pending, (state, action) => {
                state.loading = true;
                state.error = null;
                state.getStatus = "pending";
            })
            .addCase(fetchThemeImages.fulfilled, (state, action) => {
                state.themeImages = action.payload.data;
                state.loading = false;
                state.getStatus = "succeeded";
            })
            .addCase(fetchThemeImages.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
                state.getStatus = "failed";
            });

        builder
            .addCase(fetchLastImages.pending, (state, action) => {
                state.loading = true;
                state.error = null;
                state.getStatus = "pending";
            })
            .addCase(fetchLastImages.fulfilled, (state, action) => {
                state.lastImages = action.payload.data;
                state.loading = false;
                state.getStatus = "succeeded";
            })
            .addCase(fetchLastImages.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
                state.getStatus = "pending";
            });
        builder
            .addCase(fetchTechImages.pending, (state, action) => {
                state.loading = true;
                state.error = null;
                state.getStatus = "pending";
            })
            .addCase(fetchTechImages.fulfilled, (state, action) => {
                state.techImages = action.payload.data;
                state.loading = false;
                state.getStatus = "succeeded";
            })
            .addCase(fetchTechImages.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
                state.getStatus = "pending";
            });
        builder
            .addCase(fetchCommunicationImages.pending, (state, action) => {
                state.loading = true;
                state.error = null;
                state.getStatus = "pending";
            })
            .addCase(fetchCommunicationImages.fulfilled, (state, action) => {
                state.communicationImages = action.payload.data;
                state.loading = false;
                state.getStatus = "succeeded";
            })
            .addCase(fetchCommunicationImages.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
                state.getStatus = "pending";
            });

        builder
            .addCase(addCoverImage.pending, (state, action) => {
                state.addStatus = 'pending';
            })
            .addCase(addCoverImage.fulfilled, (state, action) => {
                state.addStatus = 'succeeded';
            })
            .addCase(addCoverImage.rejected, (state, action) => {
                state.addStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(addSummaryImage.pending, (state, action) => {
                state.addStatus = 'pending';
            })
            .addCase(addSummaryImage.fulfilled, (state, action) => {
                state.addStatus = 'succeeded';
            })
            .addCase(addSummaryImage.rejected, (state, action) => {
                state.addStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(addThemeImage.pending, (state, action) => {
                state.addStatus = 'pending';
            })
            .addCase(addThemeImage.fulfilled, (state, action) => {

                state.addStatus = 'succeeded';
            })
            .addCase(addThemeImage.rejected, (state, action) => {
                state.addStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(addLastImage.pending, (state, action) => {
                state.addStatus = 'pending';
            })
            .addCase(addLastImage.fulfilled, (state, action) => {

                state.addStatus = 'succeeded';
            })
            .addCase(addLastImage.rejected, (state, action) => {
                state.addStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(addTechImage.pending, (state, action) => {
                state.addStatus = 'pending';
            })
            .addCase(addTechImage.fulfilled, (state, action) => {

                state.addStatus = 'succeeded';
            })
            .addCase(addTechImage.rejected, (state, action) => {
                state.addStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(addCommunicationImage.pending, (state, action) => {
                state.addStatus = 'pending';
            })
            .addCase(addCommunicationImage.fulfilled, (state, action) => {

                state.addStatus = 'succeeded';
            })
            .addCase(addCommunicationImage.rejected, (state, action) => {
                state.addStatus = 'failed';
                state.error = action.payload;
            });


        builder
            .addCase(deleteImageThunk.pending, (state, action) => {
                state.deleteStatus = 'pending';
            })
            .addCase(deleteImageThunk.fulfilled, (state, action) => {
                state.deleteStatus = 'succeeded';
            })
            .addCase(deleteImageThunk.rejected, (state, action) => {
                state.deleteStatus = 'failed';
                state.error = action.payload;
            });

    },
});

export default imageSlice.reducer;
