import React, { useEffect, useState } from "react";
import MainLayout from "./MainLayout";
import UserService from "../../Services/UserList";
import Swal from "sweetalert2";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Paper,
  Box,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import Loader from "../utils/Loader";
import * as XLSX from "xlsx"; // Import XLSX library
import { saveAs } from "file-saver"; // Import file-saver for saving files
import PreviewIcon from "@mui/icons-material/Preview";
import DownloadIcon from "@mui/icons-material/Download";
import { useNavigate } from "react-router-dom";
import DownloadSummary from "../users/DownloadSummary";
import { useSelector, useDispatch } from "react-redux";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import TableChartIcon from "@mui/icons-material/TableChart";
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import axios from "axios";
import Apiurl from "../../Helpers/apiUrl";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dumpLoad, setDumpLoad] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [excelLoading, setExcelLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [showComponent, setshowComponent] = useState(false);
  const [projectId, setprojectId] = useState();
  const [dataFromDownloadSummary, setDataFromDownloadSummary] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const navigate = useNavigate();

  const handleDataFromDownloadSummary = (data) => {
    setDataFromDownloadSummary(data);
    setprojectId("");
  };

  useEffect(() => {
    if (
      dataFromDownloadSummary == "downloaded" ||
      dataFromDownloadSummary == "failed"
    ) {
      setExcelLoading(false);
    }
  }, [dataFromDownloadSummary]);


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDataFromDownloadSummary("");
      setprojectId("");
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [projectId, dataFromDownloadSummary]);

  useEffect(() => {
    projectsListApi();
  }, []);

  const projectsListApi = async () => {
    try {
      setLoading(true);
      const response = await UserService.Projects();
      setProjects(response.data);
      setLoading(false);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: error.message,
        timer: 3000,
      });
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page changes
  };

const filteredProjects = projects?.filter((project) => {
  const projectDate = new Date(project.created_at); 
  const from = fromDate ? new Date(fromDate) : null;
  const to = toDate ?new Date(new Date(toDate).setDate(new Date(toDate).getDate() + 1)) : null;

  const matchesSearch =
    project.client_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    project.project_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
    project.username.toLowerCase().includes(searchTerm.toLowerCase());

  const matchesDate =
    (!from || projectDate >= from) && (!to || projectDate <= to);

  return matchesSearch && matchesDate; 
});


  const handleDownloadExcel = () => {
    setLoading(true);
    const table = document.getElementById("excedata");
    const rows = table.querySelectorAll("tr");
    const data = [];

    // Loop through rows, skipping the first row (header)
    rows.forEach((row, index) => {
      if (index === 0) return; // Skip the header row

      const cells = row.querySelectorAll("td");

      // Ensure the row has the expected number of columns (21)
      // Skip rows that don't have 21 columns

      // Check if any cell is undefined and assign default values
      const rowData = [
        cells[0]?.innerText || "N/A", // SL No.
        cells[3]?.innerText || "N/A", // Project ID
        cells[4]?.innerText || "N/A", // Client
        cells[5]?.innerText || "N/A", // UserName
        cells[6]?.innerText || "N/A", // UserName
      ];
      data.push(rowData);
      // SKU Codes (comma-separated)
      const skuCodes =
        cells[7]?.innerText.split(",").map((item) => item.trim()) || []; // SKU Code column (6th cell is the SKU codes)

      // Add each SKU code as a new row with the same other column values
      
    });

    // Define the header row for the Excel file
    const sheetData = [
      [
        "SL No.",
        "Project ID",
        "Client",
        "UserName",
        "Created At",
      ],
      ...data, // Add all the data rows
    ];

    // Create the worksheet
    const ws = XLSX.utils.aoa_to_sheet(sheetData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Projects Data");

    // Write the file
    XLSX.writeFile(wb, "projects_data.xlsx");

    setLoading(false);
  };

const flattenDumpData = async () => {
    if (!fromDate || !toDate) {
      alert("Select Date First");
      return;
    }
  
    setDumpLoad(true);
    const getToken = () => localStorage.getItem("user");
    const token = getToken();

    try {
      const response = await axios.get(
        `${Apiurl}admin/project/view`,
        {
          params: { from: fromDate, to: toDate },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      if (!response.data || response.data.length === 0) {
        alert("No data found");
        setDumpLoad(false);
        return;
      }
  
      let processedData = [];
      let serialNumber = 1;
      const dumpData = response.data.data;
  
      dumpData?.forEach((item) => {
        item.sku_codes.forEach((sku) => {
          const product = item?.products?.find((p) => p.sku_code === sku);
          const moreData = item?.ProjectData?.find((p) => p.sku_code === sku);
  
          if (product) {
            processedData.push({
              "Sl No": serialNumber++,
              "Username": item.username,
              "Project ID": item.project_id,
              "Client Name": item.client_name,
              "SKU Code": product.sku_code,
              Brand: product.brand,
              Finish: product.finish || "Not Found",
              Description: product.product_desc,
              Collection: product.collection || "Not Found",
              "GST (%)": product.gst,
              Quantity: moreData?.qty,
              "MRP (₹)": product.mrp,
              "Spare FG": product.spare_fg || "N/A",
              "Product Type Group": product.product_typ_group || "Not Found",
              "Valid Date": new Date(item.valid_date).toLocaleDateString("en-GB"),
              "Created On": new Date(item.created_at).toLocaleDateString("en-GB"),
              "Updated On": new Date(item.updated_at).toLocaleDateString("en-GB"),
            });
          } else {
            console.warn(`Product not found for SKU: ${sku}`);
          }
        });
      });
  
  
      if (processedData.length > 0) {
        handleDumpExcelDownload(processedData);
      }
    } catch (error) {
      console.error("Error fetching dump data:", error);
      alert("Failed to fetch data. Please try again.");
    } finally {
        setDumpLoad(false);
    }
  };


  const handleDumpExcelDownload = (flattenedData) => {

    const headers = [
      [
        "Sl No",
        "Username",
        "Project ID",
        "Client Name",
        "SKU Code",
        "Brand",
        "Finish",
        "Description",
        "Collection",
        "GST (%)",
        "Quantity",
        "MRP (₹)",
        "Spare FG",
        "Product Type Group",
        "Valid Date",
        "Created On",
        "Updated On",
      ],
    ];

    const ws = XLSX.utils.aoa_to_sheet(headers);
    XLSX.utils.sheet_add_json(ws, flattenedData, {
      origin: "A2",
      skipHeader: true,
    });

    // Set column widths
    ws["!cols"] = [
      { wch: 5 }, // Sl No
      { wch: 20 }, // username
      { wch: 30 }, // Project ID
      { wch: 20 }, // Client Name
      { wch: 10 }, // SKU Code
      { wch: 15 }, // Brand
      { wch: 10 }, // Finish
      { wch: 30 }, // Description
      { wch: 15 }, // Collection
      { wch: 8 }, // GST (%)
      { wch: 10 }, // QTY
      { wch: 10 }, // MRP (₹)
      { wch: 10 }, // Spare FG
      { wch: 20 }, // Product Type Group
      { wch: 12 }, // Valid Date
      { wch: 12 }, // created Date
      { wch: 12 }, // updated Date
    ];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Dump Data");

    XLSX.writeFile(wb, "DumpData.xlsx");
    setLoading(false)
  };

  const viewProject = (id) => {
    try {
      navigate(`/admin/viewProjects?project_id=${id}`);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (showComponent) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [showComponent]);

  const downloadexcel = async (id) => {
    try {
      setprojectId(id);
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      {(loading||dumpLoad||excelLoading) && <Loader />}
      <MainLayout>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            flexWrap: "wrap",
            padding: 2,
          }}
        >
          {/* Search Field */}
          <TextField
            label="Search Projects"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{
                borderRadius:'5px',
              width: "100%",
              maxWidth: 300,
              backgroundColor: "#fff",
              boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
            }}
          />

          {/* Date Filters */}
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField
              label="From Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              size="small"
              sx={{
                borderRadius:'60px',
                backgroundColor: "white",
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                },
              }}
            />
            
            <IconButton disabled>
              <MultipleStopIcon  fontSize="large" className='text-green-600 bg-gray-100 rounded-full p-1 scale-75'/>
            </IconButton>
            <TextField
              label="To Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              size="small"
              sx={{
                borderRadius:'60px',
                backgroundColor: "white",
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                },
              }}
            />
          </Stack>

          {/* Action Buttons */}
          <Button
            variant="contained"
            color="primary"
            startIcon={<CloudDownloadIcon />}
            onClick={()=>flattenDumpData()}
            disabled={dumpLoad}
           
          >
            Dump
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<TableChartIcon />}
            onClick={handleDownloadExcel}
            sx={{
                backgroundColor:"#348c51"
            }
            }
          >
            Project Excel
          </Button>
        </Box>

        <Paper  sx={{ margin: "", padding: "" }}>
          <TableContainer component={Paper}>
            <Table id="excedata">
              <TableHead sx={{ background: "#1a1a1a", color: "white" }}>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    SL No.
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Download Sheet.
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Preview
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Project ID
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Client
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Username
                  </TableCell>

                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Created On
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "white" }}>
                    Time
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredProjects?.length > 0 ? (
                  filteredProjects
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(
                      (
                        {
                          id,
                          client_name,
                          username,
                          project_id,
                          valid_date,
                          created_at,
                          sku_codes,
                          updated_at,
                          products,
                        },
                        index
                      ) => (
                        <TableRow key={id}>
                          <TableCell>
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  downloadexcel(project_id);
                                  setExcelLoading(true);
                                }}
                              >
                                <DownloadIcon className="cursor-pointer hover:scale-125" />
                              </IconButton>
                            </div>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color="error"
                              onClick={() => {
                                viewProject(project_id);
                                localStorage.setItem("projectId", project_id);
                              }}
                            >
                              <PreviewIcon className="cursor-pointer hover:scale-125" />
                            </IconButton>
                          </TableCell>
                          <TableCell>{project_id}</TableCell>
                          <TableCell>{client_name}</TableCell>
                          <TableCell>{username}</TableCell>
                          <TableCell> {new Date(created_at).toLocaleDateString('en-GB')} </TableCell>
                          <TableCell> {new Date(created_at).toLocaleTimeString()} </TableCell>
                        </TableRow>
                      )
                    )
                ) : (
                  <TableRow>
                    <TableCell colSpan={24} align="center">
                      No projects found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100, 500, filteredProjects?.length]}
            component="div"
            count={filteredProjects?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              paddingTop: 2,
              paddingBottom: 2,
            }}
          />
        </Paper>
      </MainLayout>
      <div style={{ display: showComponent ? "block" : "none" }}>
        <DownloadSummary
          project={projectId}
          sendData={handleDataFromDownloadSummary}
        />
      </div>
    </>
  );
};

export default Projects;
