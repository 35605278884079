import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Apiurl from "../../Helpers/apiUrl";
import { handleApiError } from "../../Helpers/handleApiError ";
import Swal from "sweetalert2";

const getToken = () => localStorage.getItem("user");


export const fetchManual = createAsyncThunk(
    "draft/fetchManual",
    async (draftId, { rejectWithValue }) => {

        const token = getToken();
        if (!token) return rejectWithValue({ message: "No token found, authorization failed." });

        try {
            const response = await axios.get(`http://quotation.appris.in/excel/quotation_formate.pdf`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            return response.data;
        } catch (error) {
            handleApiError(error, "Please try again later.");
            return rejectWithValue(error.response?.data || { message: "Failed to fetch drafts." });
        }
    }
);

const initialState = {
    manualData: [],
    loading: false,
    getStatus: "idle",
    error: null,
};

const manualSlice = createSlice({
    name: "draft",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchManual.pending, (state) => {
                state.error = null;
                state.getStatus = "pending";
            })
            .addCase(fetchManual.fulfilled, (state, action) => {
                state.manualData = action.payload;
                state.getStatus = "succeeded";
            })
            .addCase(fetchManual.rejected, (state, action) => {
                state.error = action.payload;
                state.getStatus = "failed";
            });
    },
});

export default manualSlice.reducer;
