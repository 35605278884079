import UserSection from "../../Services/User/UserLogin";
import { useEffect, useState } from "react";
import { handleApiError } from "../../Helpers/handleApiError ";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import UserTopbar from "../UserTopbar";
import { useOutletContext } from "react-router-dom";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Loader from "../utils/Loader";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  Box,
  Grid,
  Divider,
  Container,
  Button,
  Modal,
  TablePagination,
  Tooltip,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddIcon from "@mui/icons-material/Add";
import { IoCreateSharp } from "react-icons/io5";
import RuleOutlinedIcon from "@mui/icons-material/RuleOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import Apiurl from "../../Helpers/apiUrl";
const ViewClient = () => {
  const [onEdit, setOnEdit] = useState(false);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState(null); // State to handle errors
  const [files, setFiles] = useState(null);
  const [excelData, setExcelData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { isSidebarOpen } = useOutletContext();
  const navigate = useNavigate();
  useEffect(() => {
    ClientList();
  }, []);

  const ClientList = async () => {
    try {
      const response = await UserSection.allClients();
      if (response.status) {
        setClients(response.data);
        setLoading(false); // Set loading to false after data is fetched
      }
    } catch (error) {
      setError("Failed to fetch data. Please try again later.");
      handleApiError(error, "Failed to fetch data. Please try again later.");
      setLoading(false); // Set loading to false even in case of an error
    }
  };

  // Validation state
  const [errors, setErrors] = useState({});

  // Form data state
  const [formData, setFormData] = useState({
    client_name: "",
    id: "",
    site_add: "",
    afd: "",
    add_to: "",
    username: "",
    city: "",
    state: "",
    pin: "",
    email:"",
    phone:"",
  });

  // Form validation
  const validateForm = () => {
    let errors = {};

    if (!formData.client_name) {
      errors.client_name = "Client Name is required";
    }

    if (!formData.site_add) {
      errors.site_add = "Billing Address is required";
    }

    // if (!formData.afd) {
    //   errors.afd = "Architect Firm Details are required";
    // }

    if (!formData.add_to) {
      errors.add_to = "Site Address is required";
    }
    // if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
    //   errors.email = "Email Address is invalid";
    // }
    if (!/^\d{10}$/.test(formData.phone)) {
      errors.phone = "Phone Number must be exactly 10 digits";
    }

    // if (!formData.city) {
    //   errors.city = "City is required";
    // }

    // if (!formData.state) {
    //   errors.state = "State is required";
    // }

    // if (formData.pin && !/^\d{6}$/.test(formData.pin)) {
    //   errors.pin = "Pin Code must be 6 digits";
    // }

    return errors;
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form
    const validationErrors = validateForm();

    // If there are validation errors, update the error state and return
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Proceed with the form submission if no errors
    try {
      const response = onEdit
        ? await UserSection.updateClient(formData)
        : await UserSection.addClient(formData);

      if (response.status) {
        Swal.fire({
          icon: "success",
          title: onEdit ? "Client Updated" : "Client Added",
          text: `Client ${onEdit ? "updated" : "added"} successfully`,
          timer: 3000,
        });

        setFormData({
          id: "",
          client_name: "",
          site_add: "",
          afd: "",
          add_to: "",
          username: "",
          state: "",
          city: "",
          pin: "",
          email:"",
          phone:"",
        });

        ClientList();
        setModal(false);
      }
    } catch (error) {
      handleApiError(
        error,
        `Failed to ${onEdit ? "update" : "add"} client. Please try again later.`
      );
    }
  };

  // Handle form field change
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Clear error for the current field
    setErrors({
      ...errors,
      [name]: "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Bulk file upload handler
  const addbulkclient = (event) => {
    const file = event.target.files[0]; // Access the uploaded file
    setFiles(file);

    const reader = new FileReader();
    let allSheetData = [];

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      workbook.SheetNames.forEach((sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // Get rows as an array

        // Process the data: Assuming the first row is the header, start from row 2 (index 1)
        const rowsData = jsonData.slice(1).map((row) => {
          return {
            // id: row[0],
            client_name: row[0],
            site_add: row[1],
            add_to: row[2],
            city: row[3],
            state: row[4],
            pin: row[5],
            afd: row[6],
            email: row[7],
            phone: row[8],
          };
        });

        // Add the processed data to the result (allSheetData)
        allSheetData.push(...rowsData);
      });

      // Process all the rows from all sheets
      importcsvfile1(allSheetData); // Assuming this function handles the structured data
      setExcelData(allSheetData); // Assuming you want to set the processed data in the state
      setError(""); // Reset any error message if present
    };

    reader.readAsArrayBuffer(file);
  };

  const importcsvfile1 = async (data) => {
    try {
      if (data) {
        const importData = await UserSection.addClient_bulk(data);
        if (importData.status === true) {
          Swal.fire({
            icon: "success",
            title: "Client",
            text: "Client added successfully",
            timer: 3000, // Automatically close after 3 seconds
          });
        }
        ClientList();
        setModal(false);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const downloadcsv = async (e) => {
    try {
      e.preventDefault();
      const url = "https://quotationsapis.groheapp.in/public/excel/client.xlsx";
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "sku_data.xlsx";
      anchor.click();
    } catch (error) {
      setLoading(true);
      console.log(error);
    }
  };

  if (error) {
    return <div className="text-center text-red-500 py-6">{error}</div>;
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  // Filter clients based on search query
  const filteredClients = clients.filter(
    (client) =>
      client.client_name?.toLowerCase().includes(searchQuery) ||
      client.site_add?.toLowerCase().includes(searchQuery) ||
      client.city?.toLowerCase().includes(searchQuery) ||
      client.state?.toLowerCase().includes(searchQuery) ||
      client.eamil?.toLowerCase().includes(searchQuery) ||
      client.phone?.toLowerCase().includes(searchQuery) ||
      String(client.id).toLowerCase().includes(searchQuery)
  );
  const createQuatation = (id) => {
    navigate(`/user/projects`);
    localStorage.setItem("client_id", id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const paginatedData = filteredClients.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const editClient = (client) => {
    setOnEdit(true);
    setFormData({
      id: client.id,
      client_name: client.client_name,
      site_add: client.site_add,
      city: client.city,
      state: client.state,
      pin: client.pin,
      add_to: client.add_to,
      afd: client.afd,
      email: client.email,
      phone: client.phone,
      
    });
    setModal(true);
  };

  return (
    <>
      <UserTopbar heading="Add / View Client" sidebar={isSidebarOpen} />
      {loading && <Loader />}
      {modal && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            marginLeft: 3,
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9,
          }}
        >
          <Container
            maxWidth="md"
            sx={{
              position: "relative",
              p: 5,
              bgcolor: "background.paper",
              boxShadow: 4,
              borderRadius: 2,
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "primary.dark" }}
              >
                Add New Client
              </Typography>
              <Box display="flex" gap={2}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  onClick={downloadcsv}
                  sx={{
                    bgcolor: "primary.dark",
                    textTransform: "capitalize",
                    boxShadow: 2,
                  }}
                >
                  Download Format
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  component="label"
                  startIcon={<UploadFileIcon />}
                  sx={{
                    borderColor: "primary.main",
                    color: "primary.main",
                    textTransform: "capitalize",
                    "&:hover": {
                      bgcolor: "primary.light",
                      borderColor: "primary.main",
                    },
                  }}
                >
                  Upload File
                  <input type="file" hidden onChange={addbulkclient} />
                </Button>
              </Box>
            </Box>

            <Divider sx={{ mb: 4 }} />

            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {[
                  {
                    label: "Client Name *",
                    name: "client_name",
                    error: errors.client_name,
                  },
                  {
                    label: "Billing Address *",
                    name: "site_add",
                    error: errors.site_add,
                  },
                  { label: "City ", name: "city", error: errors.city },
                  { label: "State ", name: "state", error: errors.state },
                  { label: "Pin Code", name: "pin", error: errors.pin },
                  {
                    label: "Architect Firm Details ",
                    name: "afd",
                    error: errors.afd,
                  },
                  { label: "Email", name: "email", error: errors.email },
                  { label: "Phone", name: "phone", error: errors.phone },
                  {
                    label: "Site Address *",
                    name: "add_to",
                    error: errors.add_to,
                  },
                ].map(({ label, name, error }, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <TextField
                      label={label}
                      fullWidth
                      name={name}
                      value={formData[name]}
                      onChange={handleChange}
                      error={!!error}
                      helperText={error}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          bgcolor: "background.default",
                          "& fieldset": {
                            borderColor: "grey.300",
                          },
                          "&:hover fieldset": {
                            borderColor: "grey.500",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "grey.500",
                            borderWidth: 1,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "text.secondary",
                          fontSize: "0.95rem",
                        },
                        "& .MuiFormHelperText-root": {
                          color: "error.main",
                        },
                      }}
                    />
                  </Grid>
                ))}
              </Grid>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 4, gap: 4 }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  sx={{
                    px: 4,
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    bgcolor: "secondary.main",
                    boxShadow: 2,
                    "&:hover": {
                      bgcolor: "secondary.dark",
                    },
                  }}
                >
                  {onEdit ? "Update" : "Add Client"}
                </Button>
                <Button
                  onClick={() => {
                    setModal(false);
                    setOnEdit(false);
                    setFormData({
                      client_name: "",
                      id: "",
                      site_add: "",
                      afd: "",
                      add_to: "",
                      username: "",
                      city: "",
                      state: "",
                      pin: "",
                      email:"",
                      phone:"",
                    });
                    setErrors({});
                  }}
                  variant="contained"
                  color="secondary"
                  sx={{
                    px: 4,
                    textTransform: "capitalize",
                    bgcolor: " #FF1C1C",
                    boxShadow: 2,
                    "&:hover": {
                      bgcolor: "#FF0000",
                    },
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      )}
      {/* Client Details Section (Card-like table) */}
      <div className="flex-1  shadow-lg rounded-lg">
        <Box>
          {/* Header Container with Search and Title */}
          <Box
            className="bg-gray-900"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              borderColor: "#fff",
              mb: 2,
              pb: 5,
              p: 2,
            }}
          >
            {/* <Typography variant="h5" sx={{ fontWeight: "bold", color: "#fff" }}>
              Client Details
            </Typography> */}
            <div className=" relative">
              <input
                type="text"
                onChange={handleSearchChange}
                placeholder="Search... "
                className="p-3 w-[27rem] border-2 border-teal-800 rounded-md text-black  transition duration-200"
                required
              />
              <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                <FaSearch className="text-teal-800" />
              </span>
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setModal(true)}
            >
              <PersonAddOutlinedIcon className="mr-2" /> Add Client
            </Button>
          </Box>

          {/* Table */}
          <TableContainer sx={{ mt: -3 }}>
            <Table aria-label="clients table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className="bg-gray-900"
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    Sr.No
                  </TableCell>
                  <TableCell
                    className="bg-gray-900"
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    Client ID
                  </TableCell>
                  <TableCell
                    className="bg-gray-900"
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    Client Name
                  </TableCell>
                  <TableCell
                    className="bg-gray-900"
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    Billing Address
                  </TableCell>
                  <TableCell
                    className="bg-gray-900"
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    City
                  </TableCell>
                  <TableCell
                    className="bg-gray-900"
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    State
                  </TableCell>
                  <TableCell
                    className="bg-gray-900"
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    Pin Code
                  </TableCell>
                  <TableCell
                    className="bg-gray-900"
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    Site Address
                  </TableCell>
                  <TableCell
                    className="bg-gray-900"
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    Architect Firm
                  </TableCell>

                  <TableCell
                    className="bg-gray-900"
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    Eamil
                  </TableCell>
                  <TableCell
                    className="bg-gray-900"
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    Phone
                  </TableCell>
                  <TableCell
                    className="bg-gray-900"
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                }}
              >
                {paginatedData.map((client, index) => (
                  <TableRow
                    key={client.id}
                    hover
                    sx={{ "&:hover": { bgcolor: "gray.50" } }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{client.id}</TableCell>
                    <TableCell>{client.client_name}</TableCell>
                    <TableCell>{client.site_add}</TableCell>
                    <TableCell>{client.city}</TableCell>
                    <TableCell>{client.state}</TableCell>
                    <TableCell>{client.pin}</TableCell>
                    <TableCell>{client.add_to}</TableCell>
                    <TableCell>{client.afd}</TableCell>
                    <TableCell>{client.email}</TableCell>
                    <TableCell>{client.phone}</TableCell>
                    <TableCell>
                      <span className="flex gap-5 text-white">
                        <button
                          onClick={(e) => createQuatation(client.id)}
                          sx={{ color: "primary" }}
                        >
                          {" "}
                          <Tooltip title={"Create Quote"} arrow>
                            <EditNoteIcon
                              className="bg-green-600 hover:bg-green-300 hover:text-green-600 p-1 rounded-full scale-125"
                              style={{
                                fontSize: "30px",
                                // color: "blue",
                              }}
                            />
                          </Tooltip>
                        </button>
                        <button
                          onClick={(e) => editClient(client)}
                          sx={{ color: "primary" }}
                        >
                          {" "}
                          <Tooltip title={"Edit CLient"} arrow>
                            <RuleOutlinedIcon
                              className="bg-blue-600 hover:bg-blue-300 hover:text-blue-600 p-1 rounded-full scale-110"
                              style={{
                                fontSize: "30px",
                                // color: "blue",
                              }}
                            />
                          </Tooltip>
                        </button>
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
            rowsPerPageOptions={[10, 50, 100, 500, filteredClients.length]}
            component="div"
            count={filteredClients.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </div>
    </>
  );
};

export default ViewClient;
